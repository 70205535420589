import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { clearToken, logout } from '../../../redux/Slices/authSlice';
import { AppDispatch } from '../../../../redux/store';
import HomepageStats from '../../../molecules/Homepage/HomepageStats';
import './Homepage.scss';
import SiteList from '../../../molecules/SiteManagement/ProjectsTab';
import LowerSection from '../../../molecules/Homepage/LowerSection';
const Homepage = () => {
    const navigate = useNavigate();

    const onPressLogOut = async () => {
        // Clear local storage and reset the token in the Redux store
        localStorage.clear();
        // dispatch(clearToken());

        try {
            // await dispatch(logout()).unwrap();/
        } catch (error) {
            console.error('Logout failed:', error);
        }

        // Redirect to the login page
        navigate("/login");
    };

    return (
        <div>

            <div>
                <HomepageStats />
            </div>
            <div>
                <LowerSection />
            </div>
        </div>


    )
}

export default Homepage