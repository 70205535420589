import store from '../../../redux/store';

export const checkPermission = (module_id: number, incoming_permission: string) => {

    let permissions = store.getState().permission.permissions;

    return permissions.filter((permission) => permission.module_id === module_id && permission.module_key.includes(incoming_permission)).length > 0 ? true : false;

}

export const permissionType = {
    SITE_MODULE: 1,
    RANKING_MODULE: 2,
    MANAGER_MODULE: 5,
    BROKER_MODULE: 9,
    GROUP_MODULE: 10,
    POST_MODULE: 4,
    USER_MODULE: 7,
    VISIT_MODULE: 11
}