import React from 'react'
import styled from 'styled-components'
import { image } from '../../../app/utils/common/image';
const Logo: React.FC<{ height: number, width: number }> = ({ height, width }) => {
    return (
        <LogoImg src={image.LOGO} style={{ width: `${width}px`, height: `${height}px` }} />
    )
}

export default Logo;

const LogoImg = styled.img``;