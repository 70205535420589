import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import "./ManagerDataTable.scss"
import { image } from '../../../app/utils/common/image';
import TableComponent from '../../atoms/TableComponent/Tablecomponent';
import ActionIcon from '../../atoms/ActionIcon/ActionIcon';
import OptionMenu from '../../atoms/OptionMenu/OptionMenu';
import { useNavigate } from 'react-router-dom';
import { confirmModalProps } from '../../../dto/confirm';
import ConfirmModal from '../../atoms/confirmation-modal/confirmation-modal';
import { ManagerType } from '../../../dto/Manager.dto';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { PaginationType } from '../../../dto/Pagination.dto';
import { deleteManagerAPI, getAllManagers } from '../../../redux/API/ManagerAPI';
import { setCurrentManager, setManagerPagination } from '../../../redux/Slices/managerSlice';
import { getImageURL } from '../../../app/utils/common/getImageUrl';




const ActionButtonContainer: React.FC<{ Manager: any, deleteManagerFunction: (id: number) => void }> = ({ Manager, deleteManagerFunction }) => {
    const [isMenuOpen, seIsMenuOpen] = useState(false);
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const { loading } = useAppSelector(state => state.manager)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const options = [{
        name: "Edit",
        onClick: () => {
            dispatch(setCurrentManager(Manager));
            navigate("manager/edit")
        }
    },
    {
        name: "Remove",
        onClick: () => {
            setRemoveModal(true);
        }
    }
    ];

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this manager?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        loading: loading,
        onPressPositive: () => { deleteManagerFunction(Manager.manager_id); setRemoveModal(false); },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.USER_NAME_ICON
    };

    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {isMenuOpen && <OptionMenu options={options} left={"-100px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}


const ManagerDataTable: React.FC = () => {
    const navigate = useNavigate();
    const { managers, loading, pagination } = useAppSelector(state => state.manager);
    const [refreshTable, setRefreshTable] = useState<boolean>(false)
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getAllManagers({ page: pagination.current_page }))
    }, [pagination.current_page]);

    useEffect(() => {
        if (refreshTable) {
            dispatch(getAllManagers({ page: pagination.current_page }));
        }
    }, [refreshTable])

    const columns: TableColumnsType = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            render: (text, record) => (
                <img
                    src={record.profile_image ? getImageURL(record.profile_image) : image.DEFAULT_PROFILE_IMAGE}
                    alt="Profile"
                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record: any) => (
                <div onClick={() => {
                    dispatch(setCurrentManager(record))
                    navigate(`${record.manager_id}`);
                }} style={{ cursor: "pointer" }}>
                    {record.name}
                </div>
            )
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
        },
        {
            title: 'Broker Allocated',
            dataIndex: 'brokers_allocated',
            render: (text, record) => (
                <div className='broker_alloted_table_value'>
                    <div className='text'>{record.brokers_allocated}</div>
                    <div className='add_btn' onClick={() => navigate("manager/broker/BrokerUnderManagerTable")}>
                        <img src={image.ADD_PLUS_WHITE_ICON} className='plus_img' />
                        Add
                    </div>
                </div>
            )
        },
        {
            title: 'Sites',
            dataIndex: 'sites',
            render: (text, record) => (
                <div>20</div>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <ActionButtonContainer Manager={record} deleteManagerFunction={handleDeleteManager} />
            ),
        },
    ];
    const handleDeleteManager = async (id: number) => {
        dispatch(deleteManagerAPI({ manager_id: id })).unwrap().then(() => {
            // setRefreshTable(true);
        }).catch((err: any) => {
            console.log("error in delete manager.", err)
        })
    }
    const handlePageChange = (pageNumber: number) => {
        if (!loading) {
            let tempData = { ...pagination };
            tempData.current_page = pageNumber
            dispatch(setManagerPagination(tempData));
        }
    };
    console.log(Array.from({ length: 4 }, (_, index) => index + 1))
    return (
        <div className='manager_dataTable'>
            <div>
                <TableComponent columns={columns}
                    dataSource={managers ?? []}
                    loading={loading}
                    pageNumbers={Array.from({ length: pagination.total_pages }, (_, index) => index + 1)}
                    handlePageLink={handlePageChange}
                    isPagination={pagination.total_pages > 1}
                    totalProfile={pagination.total_data}
                    prevPage={() => handlePageChange(pagination.current_page - 1)}
                    nextPage={() => handlePageChange(pagination.current_page + 1)}
                    activePage={pagination.current_page}
                    currentTotal={Math.min(pagination.current_page * pagination.page_size, (managers ?? []).length)} />
            </div>
        </div>
    )


}

export default ManagerDataTable;
