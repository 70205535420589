
import React from 'react'
import { image } from '../../../app/utils/common/image';
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';
import { useAppDispatch } from '../../../app/utils/hooks/reduxHook';
import { clearToken } from '../../../redux/Slices/authSlice';

interface LogoutPopupProps {
    logoutPopup: boolean;
    setLogoutPopup: React.Dispatch<React.SetStateAction<boolean>>;
}


const LogoutPopup: React.FC<LogoutPopupProps> = ({ setLogoutPopup, logoutPopup }) => {
    console.log('logoutPopup :>> ', logoutPopup);
    const dispatch = useAppDispatch();
    const handleLogutButton = () => {
        dispatch(clearToken())
        setLogoutPopup(false);

    }
    return (
        // <div className='logout_main'>
        <Modal>
            <div className='logutImage_container'>
                <img src={image.LOGOUT_SCREEN_ICON} className='logutImage' />
            </div>
            <div className='msg_container'>
                <p>Are you sure you want to Log out?</p>
            </div>

            <div className='authbutton_container'>
                <div>
                    <Button
                        buttonTitle="Cancel"
                        onClick={() => setLogoutPopup(false)}
                        className="cancelButton"
                    />
                </div>
                <div>
                    <Button
                        buttonTitle="Log Out"
                        onClick={() => handleLogutButton()}
                        className="LogOut_button"
                    />
                </div>

            </div>
        </Modal>

        // </div>
    )
}

export default LogoutPopup
