import React from 'react'
import { useNavigate } from 'react-router-dom';
import { image } from '../../../app/utils/common/image';
import './PostRequests.scss';
import PostProjectTab from '../../molecules/PostManagement/PostProjectTab';

const PostRequests = () => {
    const navigate = useNavigate();
    return (
        <div className='site_management_page_container'>
            <div className='site_management_header'>
                <div className='header_text'>
                    Post Request
                </div>
            </div>
            <div className='project_list_container'>
                <PostProjectTab />
            </div>
        </div>
    )
}

export default PostRequests