import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";

export const getAllManagers = createAsyncThunk(
  "manager/getAllManagers",
  async (
    { page, total_page = 7 }: { page: number; total_page?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_ALL_MANAGERS}/?page=${page}&limit=${total_page}`
      );
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all managers");
    }
  }
);

export const createManagerAPI = createAsyncThunk(
  "manager/createManager",
  async (
    data: {
      name: string;
      country_code: string;
      phone_number: string;
      email: string;
      profile_image?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `${ApiConstants.CREATE_MANAGER}`,
        data
      );
      return response.data.data;
    } catch (error: any) {
      console.log("error print", error);
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const updateManagerAPI = createAsyncThunk(
  "manager/updateManagerAPI",
  async (
    data: {
      manager_id: number;
      name?: string;
      country_code?: string;
      phone_number?: string;
      email?: string;
      profile_image?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      let { manager_id, ...reqObj } = data;
      const response = await apiClient.put(
        `${ApiConstants.UPDATE_MANAGER}/${manager_id}/update`,
        reqObj
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const deleteManagerAPI = createAsyncThunk(
  "manager/deleteManagerAPI",
  async (data: { manager_id: number }, { rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        `${ApiConstants.UPDATE_MANAGER}/${data.manager_id}/delete`
      );
      return {
        payload: response.data.data,
        manager_id: data.manager_id,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);
