import { Spin, Table, Typography } from 'antd'
import React, { FC } from 'react'
import Pagination from '../Pagination/Pagination';
import "./TableComponent.scss";
interface TableComponentProps {
    columns: any[],
    dataSource: any[],
    pageNumbers: number[];
    handlePageLink: (page: number) => void;
    prevPage: () => void;
    nextPage: () => void;
    totalProfile: number;
    activePage: number;
    currentTotal: number;
    loading?: boolean;
    isPagination?: boolean;
    RecordType?: any;
}

const TableComponent: FC<TableComponentProps> = (props) => {
    const { columns, loading = false, dataSource, pageNumbers, isPagination = false, handlePageLink, prevPage, nextPage, totalProfile, activePage, currentTotal } = props

    return (
        <div className='table'>
            <Table<any>
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                loading={{
                    spinning: loading,
                    indicator: <div className='loading_text'>Loading.........</div>,
                }}

                locale={{
                    emptyText:
                        "No Data"
                }}
            />
            {isPagination &&
                <Pagination pageNumbers={pageNumbers}
                    handlePageLink={handlePageLink}
                    totalProfile={totalProfile}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    activePage={activePage}
                    currentTotal={currentTotal} />
            }
        </div>
    )
}

export default TableComponent