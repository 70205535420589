// CustomRadioButton.tsx
import React from 'react';
import './Radio.scss';

interface CustomRadioButtonProps {

    name?: string;
    value?: string;
    checked?: boolean;
    radioClass?: string;  // optional custom color prop
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
    value,
    checked,
    // default color
    radioClass,
    onChange,
}) => {
    return (
        <div className={`custom-radio-button ${radioClass}`}>
            <input
                type="radio"

                value={value}
                checked={checked}
                onChange={onChange}

            />
            <span className="custom-radio-button__circle"></span>
        </div>
    );
};

export default CustomRadioButton;
