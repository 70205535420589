import React, { FC, useEffect, useState } from 'react';
import type { TableColumnsType } from 'antd';
import { image } from '../../../../app/utils/common/image';
import Button from '../../../atoms/Button/Button';
import TableComponent from '../../../atoms/TableComponent/Tablecomponent';
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon';
import { useNavigate } from 'react-router-dom';
import { confirmModalProps } from '../../../../dto/confirm';
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { deleteBrokerAPI, getAllBrokers } from '../../../../redux/API/BrokerAPI';
import { BrokerType } from '../../../../dto/Broker.dto';
import { setBrokerPagination, setCurrentBroker } from '../../../../redux/Slices/brokerSlice';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';

interface DataType {
    cp_id: number;
    key: React.Key;
    profile_image: string;
    name: string;
    phone_number: number;
    emailID: string;
    site_visits: number
}

const ActionButtonContainer: React.FC<{ Broker: any, pagination: any }> = ({ Broker, pagination }) => {
    const navigate = useNavigate()
    const { currentBroker, loading } = useAppSelector(state => state.broker)
    const [isMenuOpen, seIsMenuOpen] = useState(false);
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const options = [{
        name: "Edit",
        onClick: () => {
            dispatch(setCurrentBroker(Broker));
            navigate("broker/edit")
        }
    },
    {
        name: "Remove",
        onClick: () => {
            dispatch(setCurrentBroker(Broker));
            setRemoveModal(true)
        }
    }
    ];

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this broker?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: async () => {
            let cpIdToDelete: any = {}
            cpIdToDelete = currentBroker?.cp_id;

            if (cpIdToDelete) {
                try {
                    await dispatch(deleteBrokerAPI({ cpId: cpIdToDelete }));
                    setRemoveModal(false);

                    dispatch(getAllBrokers({ page: pagination.current_page }));
                } catch (error) {
                    console.error("Failed to delete broker:", error);
                    setRemoveModal(false);
                }
            }
        },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.REMOVE_USER_ICON
    };

    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {isMenuOpen && <OptionMenu options={options} left={"-100px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}

const ManageBrokerTable: FC = () => {
    const [brokerData, setBrokerData] = useState<BrokerType[]>([])
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const { brokers, loading, pagination } = useAppSelector(state => state.broker);

    useEffect(() => {
        dispatch(getAllBrokers({ page: pagination.current_page }))
    }, [pagination.current_page])

    useEffect(() => {
        setBrokerData(brokers)
    }, [brokers])

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Group Photo',
            dataIndex: 'Photo',
            render: (text, record) => {
                return (
                    <img
                        src={record.profile_image ? getImageURL(record.profile_image) : image.DEFAULT_PROFILE_IMAGE}
                        alt="Profile"
                        className='reg-profile'
                    />
                )
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => (
                // currentBroker
                // onClick={() => navigate(`/broker/${record.cp_id}`)}  // Navigate to the details page

                <div onClick={() => navigate(`${record.cp_id}`)} style={{ cursor: "pointer" }}>
                    {record.name}
                </div>
            )
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            render: (text, record) => (
                <div onClick={() => navigate(`${record.cp_id}`)} style={{ cursor: "pointer" }}>
                    {record.phone_number}
                </div>
            )
        },
        {
            title: 'Sites Visited',
            dataIndex: 'sitesVisited',
            render: (text, record) => (
                <div onClick={() => navigate(`${record.cp_id}`)} style={{ cursor: "pointer" }}>
                    {record.site_visits}
                </div>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <ActionButtonContainer Broker={record} pagination={pagination} />
            ),
        },
    ];

    const handlePageChange = (pageNumber: number) => {
        if (!loading) {
            let tempData = { ...pagination };
            tempData.current_page = pageNumber
            dispatch(setBrokerPagination(tempData));
        }
    };

    return (
        <div className='registration_dataTable'>
            <TableComponent columns={columns}
                dataSource={brokerData}
                loading={loading}
                pageNumbers={Array.from({ length: pagination.total_pages }, (_, index) => index + 1)}
                handlePageLink={handlePageChange}
                isPagination={pagination.total_pages > 1}
                totalProfile={pagination.total_data}
                prevPage={() => handlePageChange(pagination.current_page - 1)}
                nextPage={() => handlePageChange(pagination.current_page + 1)}
                activePage={pagination.current_page}
                currentTotal={Math.min(pagination.current_page * pagination.page_size, brokerData.length)} />
        </div>
    );
};

export default ManageBrokerTable;