import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { createManagerAPI, deleteManagerAPI, getAllManagers, updateManagerAPI } from "../../API/ManagerAPI";
import { ManagerSliceState } from "../../Slices/managerSlice";
import { ManagerType } from "../../../dto/Manager.dto";
import { PaginationType } from "../../../dto/Pagination.dto";
import { showErrorToast, showSuccessToast } from "../../../app/utils/common/Toast";



export const ManagerReducer = (builder: ActionReducerMapBuilder<ManagerSliceState>) => {
    builder
        .addCase(getAllManagers.pending, (state) => {
            state.loading = true;
            state.managers = [];
        })
        .addCase(getAllManagers.fulfilled, (state, action: PayloadAction<{
            manager_data: ManagerType[],
            pagination: PaginationType
        }>) => {
            state.loading = false;

            state.managers = action.payload.manager_data;
            state.refreshTable = false;
            state.pagination = action.payload.pagination;
        }).addCase(getAllManagers.rejected, (state, action: PayloadAction<any>) => {
            console.log("error");
            state.loading = false;
        })
        .addCase(createManagerAPI.pending, (state) => {
            state.loading = true;
        })
        .addCase(createManagerAPI.fulfilled, (state, action: PayloadAction<{
            manager_data: ManagerType[],
            pagination: PaginationType
        }>) => {
            state.loading = false;
            showSuccessToast("Manager created.");
            state.managers = [];
        }).addCase(createManagerAPI.rejected, (state, action: PayloadAction<any>) => {
            console.log("error");
            state.loading = false;
            if (action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                showErrorToast("Error in manager creation.")
            }
        })
        .addCase(updateManagerAPI.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateManagerAPI.fulfilled, (state, action: PayloadAction<{
            manager_data: ManagerType[],
            pagination: PaginationType
        }>) => {
            showSuccessToast("Manager updated.");
            state.loading = false;
            state.managers = [];
        }).addCase(updateManagerAPI.rejected, (state, action: PayloadAction<any>) => {
            if (action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                showErrorToast("Error in manager creation.")
            }
            state.loading = false;
        }).addCase(deleteManagerAPI.pending, (state) => {
            state.loading = true;

        }).addCase(deleteManagerAPI.fulfilled, (state, action: PayloadAction<any>) => {
            state.loading = false;
            console.log("action received", action);
            const tempManagers = [...(state.managers ?? [])]
            const filteredManagers = tempManagers.filter((manager) => manager.manager_id !== action.payload.manager_id) ?? []
            console.log("filteredManagers", filteredManagers);
            state.managers = [...filteredManagers];
        }).addCase(deleteManagerAPI.rejected, (state, action: PayloadAction<any>) => {
            if (action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                showErrorToast("Error in manager creation.")
            }
            state.loading = false;
        })

};
