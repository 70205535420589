import React, { useState } from 'react'
import { image } from '../../../../app/utils/common/image'
import { ManagerType } from '../../../../dto/Manager.dto'
import SelectBrokerModal from './SelectBrokerModal'
import Modal from '../../../atoms/Modal/Modal'
import './SelectModal.scss'

interface SelectManagersPropsType {
    onChangeData: (key: string, value: any) => void
    selectedNewManagers: {
        manager_id: number,
        broker_ids: number[],
    }[];
    setManagerModal: (data: boolean) => void;
}
export let managers = [
    {
        id: 1,
        name: "Dhruv Gopani",
        projects: 40,
        country_code: "+91",
        contact: "8849927290",
    },
    {
        id: 2,
        name: "Aisha Patel",
        projects: 35,
        country_code: "+1",
        contact: "1234567890",
        profile_image: "https://i.pravatar.cc/150?img=2"
    },
    {
        id: 3,
        name: "Raj Singh",
        projects: 27,
        country_code: "+44",
        contact: "9876543210",
        profile_image: "https://i.pravatar.cc/150?img=3"
    },
    {
        id: 4,
        name: "Emily Chen",
        projects: 22,
        country_code: "+86",
        contact: "8765432109",
        profile_image: "https://i.pravatar.cc/150?img=4"
    },
    {
        id: 5,
        name: "Carlos Mendes",
        projects: 50,
        country_code: "+55",
        contact: "1122334455",
        profile_image: "https://i.pravatar.cc/150?img=5"
    },
    {
        id: 6,
        name: "Sophia Martinez",
        projects: 33,
        country_code: "+34",
        contact: "9988776655",
        profile_image: "https://i.pravatar.cc/150?img=6"
    },
    {
        id: 7,
        name: "Liam Johnson",
        projects: 45,
        country_code: "+61",
        contact: "5566778899",
        profile_image: "https://i.pravatar.cc/150?img=7"
    },
    {
        id: 8,
        name: "Mia Wong",
        projects: 29,
        country_code: "+65",
        contact: "6677889900",
        profile_image: "https://i.pravatar.cc/150?img=8"
    },
    {
        id: 9,
        name: "Ethan Brown",
        projects: 18,
        country_code: "+49",
        contact: "7788990011",
        profile_image: "https://i.pravatar.cc/150?img=9"
    },
    {
        id: 10,
        name: "Olivia Kim",
        projects: 42,
        country_code: "+82",
        contact: "8899001122",
        profile_image: "https://i.pravatar.cc/150?img=10"
    },
    {
        id: 11,
        name: "Lucas Lee",
        projects: 30,
        country_code: "+852",
        contact: "9900112233",
        profile_image: "https://i.pravatar.cc/150?img=11"
    },
    {
        id: 12,
        name: "Amelia Davis",
        projects: 26,
        country_code: "+33",
        contact: "1011223344",
        profile_image: "https://i.pravatar.cc/150?img=12"
    },
    {
        id: 13,
        name: "Mason Wilson",
        projects: 37,
        country_code: "+27",
        contact: "1122334455",
        profile_image: "https://i.pravatar.cc/150?img=13"
    },
    {
        id: 14,
        name: "Ella Taylor",
        projects: 24,
        country_code: "+64",
        contact: "2233445566",
        profile_image: "https://i.pravatar.cc/150?img=14"
    },
    {
        id: 15,
        name: "Henry White",
        projects: 48,
        country_code: "+91",
        contact: "3344556677",
        profile_image: "https://i.pravatar.cc/150?img=15"
    },
    {
        id: 16,
        name: "Chloe Evans",
        projects: 32,
        country_code: "+1",
        contact: "4455667788",
        profile_image: "https://i.pravatar.cc/150?img=16"
    },
    {
        id: 17,
        name: "Sebastian Harris",
        projects: 31,
        country_code: "+92",
        contact: "5566778899",
        profile_image: "https://i.pravatar.cc/150?img=17"
    },
    {
        id: 18,
        name: "Ava Clark",
        projects: 23,
        country_code: "+41",
        contact: "6677889900",
        profile_image: "https://i.pravatar.cc/150?img=18"
    },
    {
        id: 19,
        name: "James Rodriguez",
        projects: 21,
        country_code: "+57",
        contact: "7788990011",
        profile_image: "https://i.pravatar.cc/150?img=19"
    },
    {
        id: 20,
        name: "Isabella Lopez",
        projects: 20,
        country_code: "+34",
        contact: "8899001122",
        profile_image: "https://i.pravatar.cc/150?img=20"
    }
];

const SelectManagers: React.FC<SelectManagersPropsType> = ({
    onChangeData,
    setManagerModal,
    selectedNewManagers
}) => {
    const [selectedManager, setSelectedManager] = useState<ManagerType>();
    const [selectBrokerModal, setSelectBrokerModal] = useState<boolean>(false);

    const addManager = (managerId: Number, brokers: Number[]) => {
        if (managerId && brokers.length > 0) {
            onChangeData("add-manager", {
                manager_id: managerId,
                broker_ids: brokers
            })
            setSelectBrokerModal(false);
        }
    }

    const isSelected = (id: number) => {
        let selected = selectedNewManagers.filter(m => m.manager_id === id)
        if (selected.length > 0) return true;
        return false;
    }
    return (
        <Modal isClose={true} onClose={() => setManagerModal(false)} >
            <div className='create_project_select_manager'>
                <div className='modal_header_text'>
                    Select Manager
                </div>
                <div className='manager-list-container'>
                    {managers.map(manager => (
                        !isSelected(manager.id) &&
                        <div className='profile_data'>
                            <div className='profile'>
                                <div className='profile_image_container'>
                                    <img src={manager.profile_image ?? image.DEFAULT_PROFILE_IMAGE} className='profile_image' />
                                </div>
                                <div className='data_container'>
                                    <div className='profile_data_container'>
                                        <div className='profile_name'>{manager.name}</div>
                                        <div className='profile_contact'>{manager.country_code} {manager.contact}</div>

                                    </div>
                                    <div className='profile_project_container'>
                                        <div className='project_count'>{manager.projects}</div>
                                        <div className='project_text'>Projects</div>
                                    </div>
                                </div>
                                <div className='add_btn_container'>
                                    <button className='btn' onClick={() => {
                                        // setSelectedManager(manager);
                                        setSelectBrokerModal(true);
                                    }}>Add</button>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="screen-button-container">

                    <button
                        className="next-button"
                        onClick={() => { }}
                        style={{ marginLeft: '30px' }}
                    >
                        Add
                    </button>
                </div>

                {
                    selectBrokerModal && selectedManager && <SelectBrokerModal onSubmit={addManager} setSelectBrokerModal={setSelectBrokerModal} selectedManager={selectedManager} />
                }
            </div>
        </Modal>

    )
}

export default SelectManagers