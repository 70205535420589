import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./Slices/authSlice";
import projectSlice from "./Slices/projectSlice";
import permissionSlice from "./Slices/permissionSlice";
import managerSlice from "./Slices/managerSlice";
// import projectSlice from "./Slices/projectSlice";
import brokerSlice from "./Slices/brokerSlice"
import groupSlice from './Slices/groupSlice'

const store = configureStore({
  reducer: {
    auth: authSlice,
    project: projectSlice,
    permission: permissionSlice,
    manager: managerSlice,
    broker: brokerSlice,
    group: groupSlice
    // project: projectSlice
  },
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

