import React from 'react';
import "./SingleSite.scss";
import { Tabs } from 'antd';
import SingleSiteDetails from '../../../../molecules/SiteManagement/SingleSiteDetails/SingleSiteDetails';
import { image } from '../../../../../app/utils/common/image';
import TrainingVideo from '../../../../molecules/SiteManagement/TrainingVideos/TrainingVideo';
import ManagerListTable from '../../../../molecules/SiteManagement/ManagerListTable/ManagerListTable';
import BrokerListTable from '../../../../molecules/SiteManagement/BrokerListTable/BrokerListTable';
import { useNavigate } from 'react-router-dom';

const ActiveTabHeader = (data: { value: string }) => (
    <div className='tab_selected'>
        {data.value}

    </div>
)

const SingleSite = () => {
    const navigate = useNavigate()
    return (
        <div className='single_site'>
            <div className='sitename-header'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    navigate(-1);
                }} />
                <div className='header_text'>Surat Textile Market Site</div>
            </div>
            <Tabs
                defaultActiveKey="1"
                tabBarStyle={{
                    borderBottom: '1px solid #C4C4C4', // Underline color for tabs
                }}
                items={[
                    {
                        label: <ActiveTabHeader value='Details' />,
                        key: '1',
                        children: <SingleSiteDetails />,
                    },
                    {
                        label: <ActiveTabHeader value='Training Videos' />,
                        key: '2',
                        children: <TrainingVideo />,
                    },
                    {
                        label: <ActiveTabHeader value='Managers' />,
                        key: '3',
                        children: <ManagerListTable />,
                    },
                    {
                        label: <ActiveTabHeader value='Brokers' />,
                        key: '4',
                        children: <BrokerListTable />,
                    }
                ]}
            />
        </div>
    )
}

export default SingleSite