import React from 'react';
import './ActionIcon.scss'

const ActionIcon = () => {
    return (
        <div className='action-dot'>
        <div className='action-first-dot'></div>
        <div className='action-first-dot'></div>
        <div className='action-first-dot'></div>
    </div>
    )
}

export default ActionIcon