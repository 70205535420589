import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import axios from "axios";
import { UserReducer } from "../Reducer/Auth/UserReducer";
import { getUserData, refreshAccessToken } from "../API/UserAPI";
import { User } from "../../dto/User.dto";
import { addPermission } from "./permissionSlice";
import { useAppDispatch } from "../../app/utils/hooks/reduxHook";
const getStore = () => {
  const store = require("../../redux/store").default;
  return store;
};

// Define types for your state and API responses
export interface AuthState {
  otpId: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  loading: boolean;
  error: string | null;
  data: any;
  access_token: string | null;
  refresh_token: string | null;
  user: User | null;
  isAuthenticated: boolean;
  isRemembered: boolean;
}

interface SendOtpPayload {
  countryCode: string;
  phoneNumber: string;
  portal: string;
}

interface VerifyOtpPayload {
  countryCode: string;
  phoneNumber: string;
  otpId: string;
  otp: string;
}

interface ResendOtpPayload {
  otpId: string;
}

// Define the initial state
const initialState: AuthState = {
  otpId: null,
  status: "idle",
  loading: false,
  error: null,
  data: null,
  access_token: null,
  refresh_token: null,
  user: null,
  isAuthenticated: false,
  isRemembered: false,
};

// Async thunk for sending OTP
export const sendOtp = createAsyncThunk(
  "auth/sendOtp",
  async (
    { phoneNumber, countryCode, portal }: SendOtpPayload,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${BASE_URL}${ApiConstants.SENDOTP}`, {
        country_code: countryCode,
        phone_number: phoneNumber,
        portal: portal,
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for verifying OTP
export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (
    { countryCode, phoneNumber, otpId, otp }: VerifyOtpPayload,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${BASE_URL}${ApiConstants.LOGIN}`, {
        country_code: countryCode,
        phone_number: phoneNumber,
        otp_id: otpId,
        otp,
      });
      const { permissions } = response.data.data.permission_details;
      getStore().dispatch(addPermission(permissions));

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Async thunk for resending OTP
export const resendOtp = createAsyncThunk(
  "auth/resendOtp",
  async ({ otpId }: ResendOtpPayload) => {
    const response = await axios.post(`${BASE_URL}${ApiConstants.RESENDOTP}`, {
      otp_id: otpId,
    });
    return response.data;
  }
);

// Async thunk for refreshing the access token
//

// export const ActivateGuestFlow = createAsyncThunk(
//     'album/ActivateGuestFlow',
//     async (data: { project_id: number, activate_guest: boolean }, { rejectWithValue }) => {
//         try {

//             const response = await apiCall({
//                 method: 'POST',
//                 url: `/project/user/activate-guest-flow/`,
//                 data
//             })

//             return response.data;
//         } catch (error: any) {
//             return rejectWithValue(error.response.data);
//         }
//     });

// Async thunk for logging out
// export const logout = createAsyncThunk(
//     'auth/logout',
//     async (_, { rejectWithValue }) => {
//         try {
//             // const accessToken = localStorage.getItem('access_token');
//             // const response = await apiClient.get(`${BASE_URL}${ApiConstants.LOGOUT}`);
//             // const response = await apiClient.get(`${BASE_URL}${ApiConstants.LOGOUT}`, {
//             //     headers: {
//             //         'Authorization': `Bearer ${accessToken}`
//             //     }
//             // });
//             const response = await apiClient({
//                 method: 'POST',
//             })
//             console.log(response)
//             return null;
//         } catch (error: any) {
//             return rejectWithValue(error.response ? error.response.data : error.message);
//         }
//     }
// );

// Create the auth slice
const authSlices = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearToken(state) {
      state.access_token = null;
      state.refresh_token = null;
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    },
    setToken(
      state,
      action: PayloadAction<{ access_token: string; refresh_token: string }>
    ) {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
      state.isAuthenticated = true;
      localStorage.setItem("access_token", action.payload.access_token);
      localStorage.setItem("refresh_token", action.payload.refresh_token);
    },
    setIsRemeber(state, action: PayloadAction<boolean>) {
      console.log("set isrember");
      state.isRemembered = action.payload;
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.access_token = action.payload;
      if (state.isRemembered) {
        localStorage.setItem("access_token", action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    UserReducer(builder);
    builder
      .addCase(sendOtp.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.otpId = action.payload.data.otp_id;
        state.error = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;

        if (action.payload.success) {
          state.user = action.payload.data.user_details;
          state.access_token = action.payload.data.token_details.access_token;
          state.isAuthenticated = true;
          state.refresh_token = action.payload.data.token_details.refresh_token;

          if (state.access_token && state.isRemembered) {
            localStorage.setItem("access_token", state.access_token);
          }

          if (state.refresh_token && state.isRemembered) {
            localStorage.setItem("refresh_token", state.refresh_token);
          }
        }
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(resendOtp.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(resendOtp.fulfilled, (state) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { clearToken, setToken, setIsRemeber, setAccessToken } =
  authSlices.actions;

export default authSlices.reducer;
