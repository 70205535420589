import React from 'react'
import ScheduledVisitTable from '../ScheduledVisitTable/ScheduledVisitTable'
import { image } from '../../../../app/utils/common/image'
import { useNavigate } from 'react-router-dom'

const SingleVisitDetails: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='single_site'>
                    <div className='sitename-header'>
                        <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                            navigate(-1);
                        }} />
                        <div className='header_text'><span style={{ color: "#5f9937", marginRight: "20px" }}>Post Requests </span> Post Request Details </div>
                    </div>
                </div>
                <div className='button_container'>
                    <div className='btn'>
                        <img className='btn_icon' src={image.FILTER_ICON} />
                        <div>
                            Filter
                        </div>
                    </div>
                    <div className='btn' onClick={() => navigate("manager/create")}>
                        <img className='btn_icon' src={image.ADD_ICON} />
                        <div>
                            Add
                        </div>
                    </div>
                </div>
            </div>
            <ScheduledVisitTable />

        </div >
    )
}

export default SingleVisitDetails
