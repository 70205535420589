import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "../../Slices/authSlice";
import {
  getUserData,
  refreshAccessToken,
  updateUserData,
} from "../../API/UserAPI";
import { showSuccessToast } from "../../../app/utils/common/Toast";
import { PermissionType } from "../../Slices/permissionSlice";
import { User } from "../../../dto/User.dto";

interface PermissionDetailsResponse {
  role_id: number;
  role_name: string;
  permissions: PermissionType[];
}

export const UserReducer = (builder: ActionReducerMapBuilder<AuthState>) => {
  builder
    .addCase(getUserData.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getUserData.fulfilled,
      (
        state,
        action: PayloadAction<{
          profile_data: User;
          permission_details: PermissionDetailsResponse;
        }>
      ) => {
        state.loading = false;
        state.access_token = localStorage.getItem("access_token");
        state.refresh_token = localStorage.getItem("refresh_token");
        state.user = action.payload.profile_data;
        state.isAuthenticated = true;
        // store.dispatch(temp(action.payload.permission_details))
        // state.access_token
      }
    )
    .addCase(getUserData.rejected, (state, action: PayloadAction<any>) => {
      console.log("error", action.payload);
      state.loading = false;
    })

    //update user data
    .addCase(updateUserData.pending, (state) => {
      state.status = "loading";
      state.loading = true;
    })
    .addCase(updateUserData.fulfilled, (state, action: PayloadAction<any>) => {
      state.status = "succeeded";
      console.log("state.status :>> ", action.payload);
      state.user = action.payload?.data;
      state.loading = false;
      showSuccessToast("User profile updated successfully.");
    })
    .addCase(updateUserData.rejected, (state, action: PayloadAction<any>) => {
      console.log("error", action.payload);
      state.loading = false;
    });
};
