import React, { useState } from 'react'
import CreateRoleModal from '../../../molecules/RoleManagement/CreateRoleModal'
import Button from '../../../atoms/Button/Button'
import "./RoleManagement.scss"

const AllRoles: React.FC = () => {
    const [AddRoleModalOpen, setAddRoleModalOpen] = useState<boolean>(false);
    const handleAddRoleButton = () => {
        setAddRoleModalOpen(true);
    }
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <h2 className='role_Heading'>Role Management</h2>
            <div className='addRoleButton'>
                <Button className='button' buttonTitle="Add" onClick={handleAddRoleButton}></Button>
            </div>
            {AddRoleModalOpen && <CreateRoleModal AddRoleModalOpen={AddRoleModalOpen} setAddRoleModalOpen={setAddRoleModalOpen} />}
        </div>

    )
}

export default AllRoles
