import React, { useEffect, useState } from 'react';
import './Profile.scss';
import { image } from '../../../../app/utils/common/image';
import InputField from '../../../atoms/InputField/InputField';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';
import Button from '../../../atoms/Button/Button';
import { updateUserData } from '../../../../redux/API/UserAPI';
import { useDispatch } from 'react-redux';
import { uploadFile } from '../../../../redux/API/ProjectAPI';
import { useNavigate } from 'react-router-dom';
import Input from '../../../atoms/Input/Input';


const Profile = () => {

    const { user, loading } = useAppSelector(state => state.auth);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [profileImage, setProfileImage] = useState<string>('');
    const [newProfileImage, setNewProfileImage] = useState<File>();
    const [isDirty, setIsDirty] = useState<any>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (user) {
            setName(user.name || '');
            setPhoneNumber(user.phone_number.toString() || '');
            setProfileImage(user.profile_image || '');
        }
    }, [user]);

    useEffect(() => {
        if (isDataChange()) setIsDirty(true);
        else setIsDirty(false);

    }, [name, phoneNumber, newProfileImage])

    const isDataChange = () => {
        if (name !== user?.name || phoneNumber.toString() !== user?.phone_number.toString() || newProfileImage) {
            return true;
        }
        return false;
    }

    const handleNameChange = (e: any) => {
        setName(e.target.value);

    };

    const handlePhoneChange = (e: any) => {
        if (e.target.value.toString().length <= 10)
            setPhoneNumber(e.target.value);

    };

    const handleProfileImageChange = (e: React.ChangeEvent<any>) => {
        const file = e.target.files?.[0];
        if (file) {
            setNewProfileImage(file);
        }
    };


    const handleUpdate = async () => {
        setLoader(true);
        const userData: any = {};
        if (name !== user?.name) {
            userData.name = name;
        }
        if (phoneNumber !== user?.phone_number.toString()) {
            userData.phone_number = phoneNumber;
        }

        try {
            if (isDirty && newProfileImage) {
                const file = (document.getElementById("file-input") as HTMLInputElement).files?.[0];
                if (file) {
                    try {
                        const uploadResponse = await uploadFile([file], "test");
                        userData.profile_image = uploadResponse.data.imageUrls[0];
                    } catch (error) {
                        console.error('File upload failed:', error);
                        return;
                    }
                }
            }
            if (Object.keys(userData).length > 0) {
                try {
                    dispatch(updateUserData(userData));
                    setIsDirty(false); // Reset dirty state after update
                } catch (error) {
                    console.error('Update failed:', error);
                }
            }
        } catch (error) {
            console.log('error :>> ', error);
        } finally {
            setLoader(false)
        }
    };
    return (
        <div className='create_project_container' >
            <div className='create_project_header_container'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    navigate(-1);
                }} />
                <div className='header_text account-title'>Account Information</div>
            </div>
            <div className='group-details-container broker-add-container'>
                <div className='group-container add-broker-container'>
                    <div style={{ position: "relative" }}>
                        <div>
                            <img src={newProfileImage ? URL.createObjectURL(newProfileImage) : profileImage ? getImageURL(profileImage) : image.DEFAULT_PROFILE_IMAGE} className='broker-profile' />
                            <div className='camera_icon'>
                                <input type="file" accept="image/*" onChange={handleProfileImageChange} style={{ display: 'none' }} id="file-input" />
                                <label htmlFor="file-input">
                                    <img src={image.CAMERA_PHOTO} alt="camera icon" className="broker-camera_image add-broker" />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='broker-main-container'>
                        <div className='group-name-container'>
                            <div className='group-photo'>Name</div>
                            <Input
                                placeholder="Enter your Name"
                                type="text"
                                name="name"
                                id="name"
                                value={name}
                                onChange={handleNameChange}
                                iconSrc={image.NAME_LOGO}
                                showIcon={true}
                            />
                        </div>
                        <div className='group-name-container'>
                            <div className='group-photo'>Phone Number</div>
                            <Input
                                placeholder="Enter Phone Number"
                                type="number"
                                name="broker number"
                                id="brokerNumber"
                                value={phoneNumber}
                                disabled={true}
                                onChange={handlePhoneChange}
                                iconSrc={image.PhoneLogo}
                                showIcon={true}
                            />
                        </div>
                    </div>
                    <div className='grp-submit-container' style={{ marginLeft: "0px" }}>
                        {loading ?
                            <div className='loadedddr'><p>Loading...</p></div>
                            : isDirty && (<Button buttonTitle='Save' onClick={handleUpdate} className='group_btn_submit' />)}
                    </div>
                </div>

            </div>



        </div>
    );
};

export default Profile;
