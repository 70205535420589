import React, { useEffect, useState } from 'react';
import { image } from '../../../app/utils/common/image';
import Card from '../../atoms/Card/Card';
import { useNavigate } from 'react-router-dom';

interface VisitListProps {
    isActive: boolean;
}

interface Visit {
    id: number;
    title: string;
    description: string;
    location: string;
    image: string;
}

const VisitsList: React.FC<VisitListProps> = ({ isActive = false }) => {
    const [loading, setLoading] = useState(true);
    const [visits, setVisits] = useState<Visit[]>([]);

    const navigate = useNavigate()
    useEffect(() => {
        // Simulate API call
        setTimeout(() => {
            const dummyVisits: Visit[] = [
                {
                    id: 1,
                    title: "Surat Textile Market",
                    description: "Adajan Patiya, Adajan, Surat. 395006",
                    location: "Surat",
                    image: image.BUILDING_IMAGE
                },
                {
                    id: 2,
                    title: "Surat Textile Market",
                    description: "SG Road, Ahmedabad. 380054",
                    location: "Ahmedabad",
                    image: image.BUILDING_IMAGE
                },
            ];
            setVisits(dummyVisits);
            setLoading(false);
        }, 2000);
    }, []);

    return (
        <div className={isActive ? 'active_projects_container' : 'completed_project_container'}>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    {visits.map((visit) => (
                        <Card key={visit.id} customClass="project_card" isGreen={isActive}>
                            <div className="post-card site-card" onClick={() => navigate(`/dashboard/visit-management/visit/${visit.id}`)}>
                                <div style={{ display: 'contents' }}>
                                    <div className="post-thumbnail-container">
                                        <img className="project-card-thumbnail" src={visit.image} alt={visit.title} />
                                    </div>
                                    <div className="site-visited-title">{visit.title}</div>
                                    <div className="site-visited-desc">{visit.description}</div>
                                </div>
                            </div>
                        </Card>
                    ))}
                </>
            )}
        </div>
    );
};

export default VisitsList;
