import React from 'react'
import ProjectsTabs from '../SiteManagement/ProjectsTab'
import TopPerformerList from './TopPerformerList'

const LowerSection = () => {
    return (

        <div className='lower_section'>
            <div className='ongoing_sites_container'>
                <div className='ongoing_header'>
                    Ongoing Sites
                </div>
                <div>
                    <ProjectsTabs />

                </div>
            </div>
            <div className='top_performer_list_container'>
                <div className='ongoing_header'>
                    Top Managers and Brokers
                </div>
                <div>
                    <TopPerformerList />

                </div>
            </div>
        </div>

    )
}

export default LowerSection