import React, { FC } from "react";
import { Modal } from 'antd';
import { confirmModalProps } from "../../../dto/confirm";
import './confirmation-modal.scss';
import { image } from "../../../app/utils/common/image";
import { useAppSelector } from "../../../app/utils/hooks/reduxHook";

interface ConfirmModalProps {
    confirmModalProps: confirmModalProps;

}

const ConfirmModal: FC<ConfirmModalProps> = props => {
    const { text, buttons, onPressNegative, onPressPositive, confirmModal, setConfirmModal, image, loading = false } = props.confirmModalProps;
    return (
        <div >
            <Modal
                className="save-modal"
                open={confirmModal}
                onCancel={() => setConfirmModal(false)}
            >
                <div className="save-container">
                    <div className="">
                        <img src={image} className="modal-image" />
                    </div>
                    <div className="save-main-container">
                        <p>{text}</p>
                        <div className="save-continue-btn">
                            {loading ?
                                <div>Loading....</div> :
                                <>
                                    <button className="confirm-continue-btn" onClick={() => onPressNegative()}>{buttons[1]}</button>
                                    <button className="btns save-changes" onClick={() => { onPressPositive() }}>{buttons[0]}</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ConfirmModal;