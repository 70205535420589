import React, { useState } from 'react'
import "./AddBrokerUnderManager.scss"
import { Button, TableColumnsType } from 'antd'
import { useNavigate } from 'react-router-dom'
import { image } from '../../../../app/utils/common/image'
import TableComponent from '../../../atoms/TableComponent/Tablecomponent'
import Checkbox from '../../../atoms/Checkbox/Checkbox'

interface DataType {
    key: React.Key;
    profile: string;
    name: string;
    phoneNumber: number;
    emailID: string;
    sitesVisited: number
}


const dataSource = Array.from({ length: 40 }).map<DataType>((_, i) => ({
    key: i,
    profile: image.PROFILE_ICON,
    name: `Edward King ${i}`,
    phoneNumber: 123456,
    emailID: 'abc@gmail.com',
    sitesVisited: 50
}));
const BrokerUnderManagerTable = () => {
    const navigate = useNavigate()

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Group Photo',
            dataIndex: 'Photo',
            render: (text, record) => {
                return (
                    <img
                        src={record.profile}
                        alt="Profile"
                        className='reg-profile'
                        onClick={() => { navigate('create') }}
                    />
                )
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Email ID',
            dataIndex: 'emailID',
        },
        {
            title: 'Sites Visited',
            dataIndex: 'sitesVisited',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <div>
                    <Checkbox checked={true} onChange={() => { }} />
                </div>
            ),
        },
    ];
    const [currentPage, setCurrentPage] = useState(1);
    const pageNumbers: any[] = [];
    const [pagination, setPagination] = useState({
        totalData: dataSource.length,
        currentPage: 1,
    });
    const pageSize = 7;
    const totalPages = Math.ceil(dataSource.length / pageSize);

    // Get data for the current page
    const currentData = dataSource.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='broker_under_manager_header'>
                    <img src={image.BACK_ICON} className='back_icon' onClick={() => navigate(-1)} />
                    <span className='manager_name' >Prakash Jadeja</span><span >(Manager)</span>
                    <div className='allocate_broker'>Allocate Broker</div>


                </div>
            </div>

            <div className='manager_dataTable'>
                <div>
                    <TableComponent columns={columns}
                        dataSource={currentData}
                        pageNumbers={pageNumbers}
                        handlePageLink={handlePageChange}
                        totalProfile={pagination.totalData}
                        prevPage={() => handlePageChange(currentPage - 1)}
                        nextPage={() => handlePageChange(currentPage + 1)}
                        activePage={currentPage}
                        currentTotal={Math.min(currentPage * pageSize, dataSource.length)} />
                </div>
            </div>
        </div>
    )
}

export default BrokerUnderManagerTable