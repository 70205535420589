import React, { ComponentType } from "react";
import { Navigate } from "react-router-dom";
import store from '../../redux/store'

interface ProtectedRouteProps {
    component: ComponentType<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component }) => {
    const authState = store.getState().auth
    const accessToken = authState.access_token ?? localStorage.getItem("access_token");
    const refreshToken = authState.refresh_token ?? localStorage.getItem("refresh_token");


    if (!accessToken && !refreshToken) {
        console.log("called")
        return <Navigate to="/login" replace />;
    }

    return <Component />;
};

export default ProtectedRoute;
