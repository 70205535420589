import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PaginationType } from "../../dto/Pagination.dto";
import { GroupType, GroupTypeManagers } from "../../dto/Groups.dto";
import { GroupReducer } from "../Reducer/Group/GroupReducer";


export interface GroupSliceState {
    loading: boolean;
    groupLoading: boolean;
    groups: GroupType[];
    managerUnderGroups: GroupTypeManagers[];
    groupPagination: PaginationType;
    refreshTable: boolean;
    groupManager?: GroupType;
}

const initialState: GroupSliceState = {
    loading: false,
    groupLoading: false,
    groups: [],
    managerUnderGroups: [],
    refreshTable: false,
    groupPagination: {
        total_data: 0,
        current_page: 1,
        page_size: 10,
        total_pages: 1,
    }

};

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setGroupPagination(state, action: PayloadAction<PaginationType>) {
            state.groups = [];
            state.groupPagination = action.payload;
        },
        setCurrentGroup: (state, action: PayloadAction<GroupType>) => {
            const { group_id, name, photo, managers } = action.payload;

            // Only update groupManager and managerUnderGroups 
            if (!state.groupManager || state.groupManager.group_id !== group_id) {
                state.groupManager = { group_id, name, photo };
            }

            if (managers && managers.length !== state.managerUnderGroups.length) {
                state.managerUnderGroups = managers.map(manager => ({
                    ...manager,
                    group_id: group_id,
                    photo: photo,
                }));
            }
        },
    },
    extraReducers: (builder) => {
        GroupReducer(builder)
    }

});

export const { setGroupPagination, setCurrentGroup } = groupSlice.actions;
export default groupSlice.reducer;