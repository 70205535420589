import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { PaginationType } from "../../../dto/Pagination.dto";
import { showErrorToast } from "../../../app/utils/common/Toast";
import { GroupSliceState } from "../../Slices/groupSlice";
import { GroupType } from "../../../dto/Groups.dto";
import { createGroupAPI, getAllGroups, getAllGroupsManagers, getAllGroupsManagersCPs, updateGroupAPI } from "../../API/GroupApi";


export const GroupReducer = (builder: ActionReducerMapBuilder<GroupSliceState>) => {
    builder
        .addCase(getAllGroups.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllGroups.fulfilled, (state, action: PayloadAction<{
            group_data: GroupType[],
            pagination: PaginationType
        }>) => {
            state.loading = false;
            state.groups = action.payload.group_data;
            state.refreshTable = false;
            state.groupPagination = action.payload.pagination;
        }).addCase(getAllGroups.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        .addCase(getAllGroupsManagers.pending, (state) => {
            state.groupLoading = true;
        })
        .addCase(getAllGroupsManagers.fulfilled, (state, action: PayloadAction<{
            group_manager_data: GroupType[],
            pagination: PaginationType
        }>) => {
            state.groupLoading = false;
            state.groups = action.payload.group_manager_data;
            state.refreshTable = false;
            state.groupPagination = action.payload.pagination;
        }).addCase(getAllGroupsManagers.rejected, (state, action: PayloadAction<any>) => {
            state.groupLoading = false;
        })
        .addCase(getAllGroupsManagersCPs.pending, (state) => {
            state.loading = true;
        })
        .addCase(getAllGroupsManagersCPs.fulfilled, (state, action: PayloadAction<{
            group_data: GroupType[],
            pagination: PaginationType
        }>) => {
            state.loading = false;
            state.groups = action.payload.group_data;
            state.refreshTable = false;
            state.groupPagination = action.payload.pagination;
        }).addCase(getAllGroupsManagersCPs.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })
        .addCase(createGroupAPI.pending, (state) => {
            state.loading = true;
        })
        .addCase(createGroupAPI.fulfilled, (state, action: PayloadAction<{
            group_data: GroupType[],
            pagination: PaginationType
        }>) => {
            state.loading = false;
            state.groups = [];
        }).addCase(createGroupAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
            if (action.payload.message) {
                showErrorToast(action.payload.message)
            } else {
                showErrorToast("Error in manager creation.")
            }
        })
        .addCase(updateGroupAPI.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateGroupAPI.fulfilled, (state, action: PayloadAction<{
            group_data: GroupType[],
            pagination: PaginationType
        }>) => {
            state.loading = false;
            state.groups = [];
        }).addCase(updateGroupAPI.rejected, (state, action: PayloadAction<any>) => {
            state.loading = false;
        })

};
