import React, { useEffect, useRef, useState } from 'react'
import { image } from '../../../../app/utils/common/image';
import { useNavigate, useParams } from 'react-router-dom';
import ManageGroupHeader from './ManageGroupHeader';
import './ManageGroupDetails.scss'
import GroupMBComponent from './GroupMBComponet/GroupMBComponent';
import Button from '../../../atoms/Button/Button';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import { confirmModalProps } from '../../../../dto/confirm';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { createGroupAPI, getAllGroupsManagers, updateGroupAPI } from '../../../../redux/API/GroupApi';
import { uploadFile } from '../../../../redux/API/ProjectAPI';
import { showErrorToast } from '../../../../app/utils/common/Toast';
import { getAllManagers } from '../../../../redux/API/ManagerAPI';
import { setCurrentGroup } from '../../../../redux/Slices/groupSlice';

const ManageGroupDetails = () => {
    const navigate = useNavigate();
    let { type } = useParams();
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [newProfilePhoto, setNewProfilePhoto] = useState<File>();
    const [profilePhoto, setProfilePhoto] = useState<string | null>();
    const [showError, setShowError] = useState<boolean>(false);
    const [isDataChange, setIsDataChange] = useState<boolean>(false);
    const [selectedManagerIds, setSelectedManagerIds] = useState<string[]>([]);
    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const { groupManager, groupLoading, managerUnderGroups } = useAppSelector(state => state.group);
    const { managers, pagination, loading: loadingManager } = useAppSelector(state => state.manager);

    useEffect(() => {
        if (type === 'create') {
            dispatch(getAllManagers({ page: pagination?.current_page }));
        }
        if (type === "edit" || type === "details") {
            if (groupManager && groupManager.group_id) {
                // Only fetch managers if the group ID changes or if managers haven't been fetched yet
                if (!managerUnderGroups.length) {
                    dispatch(getAllGroupsManagers({ group_id: groupManager.group_id }))
                        .unwrap()
                        .then((managersData) => {
                            dispatch(setCurrentGroup({ ...groupManager, managers: managersData }));
                        })
                        .catch((err) => {
                            console.log("Error fetching group managers:", err);
                        })
                }
            } else {
                navigate("/dashboard/manage-group");
            }
        }
    }, [type, groupManager?.group_id, managerUnderGroups.length, dispatch, pagination?.current_page]);

    const handleManagerSelect = (manager_id: string) => {
        setSelectedManagerIds(prev =>
            prev.includes(manager_id)
                ? prev.filter(id => id !== manager_id) // Deselect if already selected
                : [...prev, manager_id] // Select if not in array
        );
    };


    useEffect(() => {
        if (type) {
            if (!["create", "edit", "details"].includes(type)) {
                navigate("/login");
            }
        } else {
            navigate("/login");
        }
    }, []);

    useEffect(() => {
        if (groupManager) {
            if (handleDataChange()) {
                setIsDataChange(true);
            } else {
                setIsDataChange(false);
            }
        }
    }, [name, newProfilePhoto]);

    useEffect(() => {
        if (type === "edit" || type === "details") {
            if (groupManager) {
                setName(groupManager.name);
                setProfilePhoto(groupManager.photo);

                if (type === "details") {
                    // In "details" mode, we only need to fetch managers and display them
                    dispatch(getAllGroupsManagers({ group_id: groupManager.group_id }))
                        .unwrap()
                        .then((managersData) => {
                            dispatch(setCurrentGroup({ ...groupManager, managers: managersData }));
                        })
                        .catch((err) => {
                            console.log("Error fetching group managers:", err);
                        });
                }
            } else {
                navigate("/dashboard/manage-group");
            }
        }
    }, [type, groupManager]);



    const handleDataChange = () => {
        if (groupManager) {
            if (groupManager.name !== name || newProfilePhoto) return true;
            return false;
        }
        return false;
    }

    const handleSubmit = async () => {
        if (name.length > 0 && selectedManagerIds.length > 0) {
            setIsLoading(true);
            let profile_image_url;

            // Only upload image if there is a new profile photo
            if (newProfilePhoto) {
                try {
                    const imageResp = await uploadFile([newProfilePhoto], "profile");
                    profile_image_url = imageResp.data.imageUrls[0];
                } catch (err) {
                    showErrorToast("Error in uploading profile image.");
                    setIsLoading(false);
                    return;
                }
            }

            if (type === "create") {
                // Only create a new group if there are actual changes
                if (selectedManagerIds) {
                    dispatch(createGroupAPI({
                        name,
                        photo: profile_image_url,
                        manager_ids: selectedManagerIds
                    })).unwrap().then(() => {
                        navigate("/dashboard/manage-group");
                    }).catch(err => {
                        console.log("Group creation error", err);
                    });
                }
            } else if (type === "edit" && isDataChange && groupManager) {
                let updatedData: any = {}
                if (newProfilePhoto) updatedData.profile_image = profile_image_url;
                if (groupManager?.name !== name) updatedData.name = name;
                updatedData.group_id = groupManager.group_id;
                updatedData.add_manager_ids = selectedManagerIds;

                dispatch(updateGroupAPI(updatedData)).unwrap().then(() => {
                    navigate("/dashboard/manage-group");
                }).catch(err => {
                    console.log("Group update error", err);
                }).finally(() => {
                    setIsLoading(false); // Stop loader
                });
            }
        } else {
            setShowError(true);
        }
    }
    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this manager?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: () => { },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.REMOVE_USER_ICON
    };

    return (
        <div className='create_project_container' >
            {isLoading && <div>..loading</div>}
            {
                <input type='file' accept='image/*' style={{ display: "none" }} ref={inputRef} onChange={(e) => {
                    if (e.target.files) {
                        setNewProfilePhoto(e.target.files[0])
                    }
                }} />
            }
            <div className='create_project_header_container'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    navigate(-1);
                }} />
                <div className='header_text'>{type === "details" && <span style={{ color: "#5f9937", marginRight: "20px" }}>Manage Groups </span>}{`${type === "create" ? 'Create Group' : type === "edit" ? 'Edit Group' : 'Group Details'} `}</div>
            </div>
            <div className='group-details-container'>
                <ManageGroupHeader name={name} setName={setName} newProfilePhoto={newProfilePhoto} showError={showError} profilePhoto={profilePhoto} inputRef={inputRef} isEditMode={type !== "details"} />
                <GroupMBComponent managers={managers} handleManagerSelect={handleManagerSelect} selectedManagerIds={selectedManagerIds} managerUnderGroups={managerUnderGroups} groupLoading={groupLoading} loadingManager={loadingManager} type={type} showError={showError} />
                {type !== "details" && (
                    <div className='grp-submit-container'>
                        <Button buttonTitle='Submit' onClick={handleSubmit} className='group_btn_submit' />
                    </div>
                )}
            </div>

            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}

export default ManageGroupDetails