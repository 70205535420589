import React from 'react'
import ManageBrokerTable from '../../molecules/ManageBrokersComponent/ManageBrokerTable/ManageBrokerTable';
import './ManageBrokers.scss'
import { Button } from 'antd';
import { image } from '../../../app/utils/common/image';
import { useNavigate } from 'react-router-dom';

const ManageBrokers = () => {
    const navigate = useNavigate()
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='allManager'>
                    Manage Brokers
                </div>
                <div className='button_container'>
                    <div className='btn'>
                        <img className='btn_icon' src={image.FILTER_ICON} />
                        <div>
                            Filter
                        </div>
                    </div>
                    <div className='btn' onClick={() => { navigate('broker/create') }}>
                        <img className='btn_icon' src={image.ADD_ICON} />
                        <div>
                            Add
                        </div>
                    </div>
                </div>
            </div>

            <div className='manager_data_table'>
                <ManageBrokerTable />
            </div>
        </div>
    )
}

export default ManageBrokers