import React, { useState } from 'react'
import { image } from '../../../../app/utils/common/image'
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon'
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import { confirmModalProps } from '../../../../dto/confirm';

const ActionButtonContainer = () => {
    const [isMenuOpen, seIsMenuOpen] = useState<boolean>(false);
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const options = [{
        name: "Edit",
        onClick: () => {
            console.log("edit click");
        }
    },
    {
        name: "Remove",
        onClick: () => {
            setRemoveModal(true)
        }
    }
    ];

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this broker?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: () => { },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.ADD_ICON
    };

    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {isMenuOpen && <OptionMenu options={options} left={"-147px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}

const WorkedWithBrokers = () => {
    return (
        <div className='group-manager-container worked-manager'>
            <div className='manager-header-flex'>
                <div className='group-manager-heading'>Worked with Brokers</div>
            </div>
            <div className='profile_data'>
                <div className='profile'>
                    <div className='profile_image_container'>
                        <img src={image.DEFAULT_PROFILE_IMAGE} className='profile_image' />
                    </div>
                    <div className='data_container'>
                        <div className='profile_data_container'>
                            <div className='profile_name'>Mark Lewis</div>
                            <div className='profile_contact'>mark@gmail.com</div>

                        </div>
                        <div className='profile-end-flex'>
                            <div className='profile_project_container'>
                                <div className='project_text'>+91 7675847384</div>
                            </div>
                            <ActionButtonContainer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkedWithBrokers