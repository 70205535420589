import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ManagerType } from "../../dto/Manager.dto";
import { PaginationType } from "../../dto/Pagination.dto";
import { ManagerReducer } from "../Reducer/Manager/ManagerReducer";



export interface ManagerSliceState {
    loading: boolean;
    managers: ManagerType[];
    pagination: PaginationType;
    refreshTable: boolean;
    currentManager?: ManagerType;
}



const initialState: ManagerSliceState = {
    loading: false,
    managers: [],
    refreshTable: false,
    pagination: {
        total_data: 0,
        current_page: 1,
        page_size: 7,
        total_pages: 1,
    }

};

const managerSlice = createSlice({
    name: 'manager',
    initialState,
    reducers: {
        setManagerPagination(state, action: PayloadAction<PaginationType>) {
            state.managers = [];
            state.pagination = action.payload;
        },
        setCurrentManager(state, action: PayloadAction<ManagerType>) {
            state.currentManager = action.payload;
        }
    },
    extraReducers: (builder) => {
        ManagerReducer(builder)
    }

});

export const { setManagerPagination, setCurrentManager } = managerSlice.actions;
export default managerSlice.reducer;