import React, { FC, useState } from 'react';
import type { TableColumnsType } from 'antd';
import { image } from '../../../app/utils/common/image';
import Button from '../../atoms/Button/Button';
import TableComponent from '../../atoms/TableComponent/Tablecomponent';

interface DataType {
    key: React.Key;
    profile: string;
    name: string;
    phoneNumber: number;
    emailID: string;
}

const columns: TableColumnsType<DataType> = [
    {
        title: 'Profile',
        dataIndex: 'profile',
        render: (text, record) => (
            <img
                src={record.profile}
                alt="Profile"
                className='reg-profile'
            />
        ),
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
    },
    {
        title: 'Action',
        dataIndex: 'action',
        render: (text, record) => (
            <div className='reject-accept-btn'>
                <Button
                    buttonTitle="Reject"
                    onClick={() => { }}
                    className={'RejectButton'}
                />
                <Button
                    buttonTitle="Accept"
                    onClick={() => { }}
                    className={'RejectButton AcceptButton'}
                />
            </div>
        ),
    },
];

const dataSource = Array.from({ length: 40 }).map<DataType>((_, i) => ({
    key: i,
    profile: image.PROFILE_ICON,
    name: `Edward King ${i}`,
    phoneNumber: 7573810452,
    emailID: 'abc@gmail.com',
}));

// Custom itemRender for pagination
// const customItemRender: PaginationConfig["itemRender"] = (
//     page,
//     type,
//     originalElement
// ) => {
//     if (type === "prev") {
//         return (
//             <div className='prev-page-button'>
//                 <div>
//                     <img src={image.PREV_ICON} />
//                     <img src={image.PREV_LIGHT_ICON} />
//                 </div>
//                 <div>Previous</div>
//             </div>
//         );
//     }
//     if (type === "next") {
//         return (
//             <div className='prev-page-button'>
//                 <div>Next</div>
//                 <div>
//                     <img src={image.PREV_ICON} style={{ transform: 'scaleX(-1)' }} />
//                     <img src={image.PREV_LIGHT_ICON} style={{ transform: 'scaleX(-1)' }} />
//                 </div>

//             </div>
//         );
//     }
//     return originalElement;
// };

const RegistrationReqTable: FC = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageNumbers: any[] = [];
    const [pagination, setPagination] = useState({
        totalData: dataSource.length,
        currentPage: 1,
    });
    const pageSize = 7;
    const totalPages = Math.ceil(dataSource.length / pageSize);

    // Get data for the current page
    const currentData = dataSource.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className='registration_dataTable'>
            <TableComponent columns={columns}
                dataSource={currentData}
                pageNumbers={pageNumbers}
                handlePageLink={handlePageChange}
                totalProfile={pagination.totalData}
                prevPage={() => handlePageChange(currentPage - 1)}
                nextPage={() => handlePageChange(currentPage + 1)}
                activePage={currentPage}
                currentTotal={Math.min(currentPage * pageSize, dataSource.length)} />
        </div>
    );
};

export default RegistrationReqTable;