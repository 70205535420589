import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { uploadFile } from '../../../../redux/API/ProjectAPI'
import { image } from '../../../../app/utils/common/image'

import "./CreateProject.scss"
import Checkbox from '../../../atoms/Checkbox/Checkbox'
import AddButton from '../../../atoms/CustomButton/AddButton/AddButton'
import { managers } from '../Modals/SelectManagers'
import CustomRadioButton from '../../../atoms/RadioButton/Radio'


// dummy manager data

const CreateProjectModal: React.FC = () => {
    const [screen, setScreen] = useState(1)
    const [selectedThumbnail, setSelectedThumbnail] = useState<File | undefined>()
    const [projectDescription, setProjectDescription] = useState({
        title: '',
        description: '',
        incentive: '',
        unit_price: '',
    })
    const [projectAddress, setProjectAddress] = useState({
        email: '',
        address: '',
        city: '',
        phone: '',
        zipcode: '',
    })
    const [trainingVideos, setTrainingVideos] = useState<File[]>([])
    const [newSiteFlyers, setNewSiteFlyers] = useState<File[]>([])
    const [trainingVideoThumbnails, setTrainingVideoThumbnails] = useState<
        File[]
    >([])
    const [newProjectBrochure, setNewProjectBrochure] = useState<File[]>([])
    const [newSitePhotos, setNewSitePhotos] = useState<File[]>([])
    const [selectedNewManagers, setSelectedManagers] = useState<{
        manager_id: number,
        broker_ids: number[],
    }[]>([])
    const [selectedBrokers, setSelectedBroker] = useState<Number[]>([])
    const inputSiteMediaRef = useRef<HTMLInputElement>(null);
    const inputSiteFlyerRef = useRef<HTMLInputElement>(null);
    const inputBrochureRef = useRef<HTMLInputElement>(null);
    const inputTrainingVideoRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        console.log(selectedNewManagers)
    }, [selectedNewManagers])

    const onChangeData = (key: string, value: any) => {
        switch (key) {
            case 'title':
                setProjectDescription({
                    ...projectDescription,
                    title: value.toString(),
                })
                return
            case 'description':
                setProjectDescription({
                    ...projectDescription,
                    description: value.toString(),
                })
                return
            case 'incentive':
                setProjectDescription({
                    ...projectDescription,
                    incentive: value.toString(),
                })
                return
            case 'unit_price':
                setProjectDescription({
                    ...projectDescription,
                    unit_price: value.toString(),
                })
                return
            case 'thumbnail':
                setSelectedThumbnail(value)
                return
            case 'address':
                setProjectAddress({
                    ...projectAddress,
                    address: value.toString(),
                })
                return

            case 'zipcode':
                setProjectAddress({
                    ...projectAddress,
                    zipcode: value.toString(),
                })
                return
            case 'city':
                setProjectAddress({
                    ...projectAddress,
                    city: value.toString(),
                })
                return
            case 'phone':
                setProjectAddress({
                    ...projectAddress,
                    phone: value.toString(),
                })
                return
            case 'email':
                setProjectAddress({
                    ...projectAddress,
                    email: value.toString(),
                })
                return
            case 'training_videos':
                value.forEach((file: File) => {
                    if (file && file instanceof File) {
                        generateThumbnail(file)
                    }
                })
                setTrainingVideos([...trainingVideos, ...value])
                return
            case 'site-flyers':
                setNewSiteFlyers([...newSiteFlyers, ...value])
                return
            case 'newBrochure':
                setNewProjectBrochure([...newProjectBrochure, ...value])
                return
            case 'site-photos':
                setNewSitePhotos([...newSitePhotos, ...value])
                return
            case 'add-manager':
                setSelectedManagers([...selectedNewManagers, value])
                return;
            default:
                return
        }
    }

    const removeMedia = (key: string, index: number) => {
        switch (key) {
            case 'flyers':
                let filteredFlyers = newSiteFlyers.filter(
                    (data, dataIndex) => index !== dataIndex,
                )
                setNewSiteFlyers(filteredFlyers);
                return;
            case 'site-photos':
                let filteredSitePhotos = newSitePhotos.filter(
                    (data, dataIndex) => index !== dataIndex,
                )
                setNewSitePhotos(filteredSitePhotos);
                return;
            case 'pdf':
                let filteredSitePdfs = newProjectBrochure.filter(
                    (data, dataIndex) => index !== dataIndex,
                )
                setNewProjectBrochure(filteredSitePdfs);
                return;
            default:
                return;
        }
    }

    const handleNext = () => {
        if (screen <= 4) setScreen((pre) => pre + 1)
    }
    const handlePrevious = () => {
        if (screen > 1) setScreen((pre) => pre - 1)
    }

    const handleRemoveTrainingVideo = (index: number) => {
        let videoData = trainingVideos.filter(
            (data, dataIndex) => dataIndex !== index,
        )
        let thumbnailData = trainingVideoThumbnails.filter(
            (data, dataIndex) => dataIndex !== index,
        )
        setTrainingVideos(videoData)
        setTrainingVideoThumbnails(thumbnailData)
    }

    const onSubmitClick = async () => {
        console.log('Data ==>')
        console.log(projectDescription)
        console.log(projectAddress)

        let payload = {
            name: projectDescription.title,
            description: projectDescription.description,
            amenities: projectDescription.unit_price,
            country_code: '+91',
            phone_number: projectAddress.phone,
            city: projectAddress.city,
            zip_code: projectAddress.zipcode,
            media_urls: {
                sub_project: [],
                thumbnail: {},
                highlights: [],
                brochure: {},
                common: [],
            },
        }
        try {
            if (selectedThumbnail) {
                let thumbnailUploadResponse = await uploadFile([
                    selectedThumbnail,
                ], "test")
                if (thumbnailUploadResponse.success) {
                    payload.media_urls.thumbnail = {
                        type: 0,
                        url: thumbnailUploadResponse.data.imageUrls[0],
                    }
                }
            }
        } catch (err) {
            console.log('error in project creation', err)
        }
    }

    const generateThumbnail = (file: File) => {
        const videoUrl = URL.createObjectURL(file)
        const videoElement = document.createElement('video')
        videoElement.src = videoUrl

        videoElement.onloadeddata = () => {
            videoElement.currentTime = 2 // Capture time for thumbnail
        }

        videoElement.onseeked = () => {
            const canvasElement = document.createElement('canvas')
            canvasElement.width = videoElement.videoWidth
            canvasElement.height = videoElement.videoHeight
            const context = canvasElement.getContext('2d')

            if (context) {
                context.drawImage(
                    videoElement,
                    0,
                    0,
                    canvasElement.width,
                    canvasElement.height,
                )

                // Convert canvas to Blob and create a File object
                canvasElement.toBlob((blob) => {
                    if (blob) {
                        const thumbnailFile = new File(
                            [blob],
                            `${file.name}-thumbnail.png`,
                            { type: 'image/png' },
                        )
                        setTrainingVideoThumbnails((prev) => [...prev, thumbnailFile])
                    }

                    URL.revokeObjectURL(videoUrl)
                }, 'image/png')
            }
        }

        videoElement.onerror = () => {
            console.error('Error loading video file:', file.name)
            URL.revokeObjectURL(videoUrl)
        }
    }
    function truncatePDFName(name: string) {
        const maxLength = 5;
        return name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
    }

    const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files ? Array.from(event.target.files) : [];
        onChangeData("training_videos", files);


    };
    return (
        <Container className="project_create_container">
            {
                <>

                    <input
                        type="file"
                        multiple
                        accept="image/*"
                        ref={inputSiteMediaRef}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            if (e.target.files) {
                                const filesArray = Array.from(e.target.files)
                                onChangeData('site-photos', filesArray)
                            }
                        }}
                    />
                    <input
                        type="file"
                        accept="image/*"
                        ref={inputSiteFlyerRef}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            if (e.target.files) {
                                const filesArray = Array.from(e.target.files)
                                onChangeData('site-flyers', filesArray)
                            }
                        }}
                    />
                    <input
                        type="file"
                        multiple
                        accept="application/pdf"
                        ref={inputBrochureRef}
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            if (e.target.files) {
                                const filesArray = Array.from(e.target.files)
                                onChangeData('newBrochure', filesArray)
                            }
                        }}
                    />
                    <input type='file' multiple accept='video/*' ref={inputTrainingVideoRef} style={{ display: "none" }} onChange={(e) => {
                        if (e.target.files) {
                            handleVideoUpload(e)
                        }
                    }} />
                </>
            }
            <div className="screen_container">
                <div className='add_site_photo'>
                    <div className='add_photo_img' onClick={() => {
                        if (inputSiteMediaRef.current) {
                            inputSiteMediaRef.current.value = ''
                            inputSiteMediaRef.current.click()
                        }
                    }}>
                        <img src={image.ADD_IMAGE_PLACEHOLDER} />
                        <div className='add_image_text'>Add Image</div>
                    </div>

                    {
                        newSitePhotos.map((photo) => (
                            <div className='site_photos'>
                                <img className='img' src={URL.createObjectURL(photo)} />
                                <img className='cancel_icon' src={image.CLOSE_ICON} />
                            </div>
                        ))
                    }
                </div>
                <div className='project_data'>
                    <div className='project_information'>
                        <div className='input_container'>
                            <label className='label'>Site Name</label>
                            <input type="text" className='input' placeholder='enter site' value={projectDescription.title} onChange={(e) => {
                                onChangeData("title", e.target.value);
                            }} />
                        </div>
                        <div className='input_container'>
                            <label className='label'>Address</label>
                            <input type="text" className='input' value={projectAddress.address} onChange={(e) => {
                                onChangeData("address", e.target.value);

                            }} />
                        </div>
                        <div className='input_container number_input'>
                            <label className='label'>Site Price</label>
                            <input type="number" className='input number_input' value={projectDescription.unit_price} onChange={(e) => {
                                onChangeData("unit_price", e.target.value);

                            }} />
                        </div>
                        <div className='input_container '>
                            <label className='label'>Incentive(Optional)</label>
                            <input type="number " className='input number_input' value={projectDescription.incentive} onChange={(e) => {
                                onChangeData("incentive", e.target.value);
                            }} />
                        </div>
                    </div>
                    <div className='project_description'>
                        <div className='desc_container'>
                            <label className='label'>Description</label>
                            <input type="text" className='input number_input' onChange={(e) => {
                                onChangeData("description", e.target.value);

                            }} />
                        </div>
                    </div>
                    <div className='project_media'>
                        <div className='site_fylers_container'>
                            <label className='label'>
                                Site Flyers
                            </label>
                            {newSiteFlyers.length > 0 ?
                                <div className='add_site_fylers_container'>
                                    <img src={URL.createObjectURL(newSiteFlyers[0])} className='site_flyers_image' />
                                    <img className='close_icon' src={image.CLOSE_ICON} onClick={() => setNewSiteFlyers([])} />
                                </div>

                                :
                                <div className='add_site_fylers_container' onClick={() => {
                                    inputSiteFlyerRef.current?.click();
                                }}>
                                    <img src={image.ADD_IMAGE_PLACEHOLDER} className='add_site_flyers_place_holder_img' />
                                    <div className='add_image_text label'>Add Site Flyers</div>
                                </div>
                            }

                        </div>
                        <div className='site_pdf_container'>
                            <label className='label'>Site PDFs</label>
                            <div className='pdfs-container'>
                                <div className='add_photo_img add_pdf_container' onClick={() => {
                                    inputBrochureRef.current?.click();
                                }}>
                                    <img src={image.ADD_PDF_PLACEHOLDER} />
                                    <div className='add_image_text'>Add PDFs</div>
                                </div>
                                {newProjectBrochure.map((file, index) => (
                                    <div className='pdf_container'>
                                        <img src={image.PDF_PLACEHOLDER_IMAGE} className='pdf_img' />
                                        <div className='pdf_name_text'>{truncatePDFName(file.name)}</div>
                                        <img
                                            className="cancelIcon"
                                            src={image.CLOSE_ICON}
                                            onClick={() => removeMedia("pdf", index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='training_video_section'>
                        <label className='label'>Training Video</label>
                        <div className='site_training_video_container'>
                            <div className='add_photo_img' onClick={() => {
                                if (inputTrainingVideoRef.current) {
                                    inputTrainingVideoRef.current.value = ''
                                    inputTrainingVideoRef.current.click()
                                }
                            }}>
                                <img src={image.ADD_VIDEO_PLACEHOLDER} style={{ marginLeft: "0" }} />
                                <div className='add_image_text'>Add Videos</div>
                            </div>
                            {trainingVideoThumbnails.map((thumbnail) => (

                                <div className='site_video' >
                                    <img className='img' src={URL.createObjectURL(thumbnail)} />
                                    <img className='cancel_icon' src={image.CLOSE_ICON} />
                                </div>
                            )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='group_select_ui'>
                <div className='grp_container'>
                    <h1 className='grp_header_text'>Select Group</h1>
                    <div className='grp_header'>
                        <div className='tab1 center_div'>Image</div>
                        <div className='tab2 center_div'>Name</div>
                        <div className='tab3 center_div'>Total Managers</div>
                        <div className='tab4 center_div'>Total Brokers</div>
                        <div className='tab5 center_div'>Select</div>
                    </div>
                    <div className='grp_body_container'>
                        <div className='grp_body'>
                            <div className='tab1  center_div'>
                                <img className='grp_profile_img' src={image.DEFAULT_PROFILE_IMAGE} />
                            </div>
                            <div className='tab2 center_div'>Dhruv Gopani</div>
                            <div className='tab3 center_div'>10</div>
                            <div className='tab4 center_div'>15</div>
                            <div className='tab5 center_div'>
                                <Checkbox checked={true} onClick={() => { console.log("checkbox") }} />
                            </div>
                        </div>
                        <div className='grp_body'>
                            <div className='tab1  center_div'>
                                <img className='grp_profile_img' src={image.DEFAULT_PROFILE_IMAGE} />
                            </div>
                            <div className='tab2 center_div'>Dhruv Gopani</div>
                            <div className='tab3 center_div'>55</div>
                            <div className='tab4 center_div'>45</div>
                            <div className='tab5 center_div'>
                                <Checkbox checked={true} onChange={() => { console.log("checkbox") }} />
                            </div>
                        </div>
                        <div className='grp_body'>
                            <div className='tab1  center_div'>
                                <img className='grp_profile_img' src={image.DEFAULT_PROFILE_IMAGE} />
                            </div>
                            <div className='tab2 center_div'>Dhruv Gopani</div>
                            <div className='tab3 center_div'>55</div>
                            <div className='tab4 center_div'>45</div>
                            <div className='tab5 center_div'>
                                <Checkbox checked={true} onChange={() => { console.log("checkbox") }} />
                            </div>
                        </div>
                        <div className='grp_body'>
                            <div className='tab1  center_div'>
                                <img className='grp_profile_img' src={image.DEFAULT_PROFILE_IMAGE} />
                            </div>
                            <div className='tab2 center_div'>Dhruv Gopani</div>
                            <div className='tab3 center_div'>34</div>
                            <div className='tab4 center_div'>23</div>
                            <div className='tab5 center_div'>
                                <Checkbox checked={true} onChange={() => { console.log("checkbox") }} />
                            </div>
                        </div>
                        <div className='grp_body'>
                            <div className='tab1  center_div'>
                                <img className='grp_profile_img' src={image.DEFAULT_PROFILE_IMAGE} />
                            </div>
                            <div className='tab2 center_div'>Dhruv Gopani</div>
                            <div className='tab3 center_div'>Total Managers</div>
                            <div className='tab4 center_div'>Total Brokers</div>
                            <div className='tab5 center_div'>
                                <Checkbox checked={true} onChange={() => { console.log("checkbox") }} />
                            </div>
                        </div>
                        <div className='grp_body'>
                            <div className='tab1  center_div'>
                                <img className='grp_profile_img' src={image.DEFAULT_PROFILE_IMAGE} />
                            </div>
                            <div className='tab2 center_div'>Dhruv Gopani</div>
                            <div className='tab3 center_div'>Total Managers</div>
                            <div className='tab4 center_div'>Total Brokers</div>
                            <div className='tab5 center_div'>
                                <Checkbox checked={true} onChange={() => { console.log("checkbox") }} />
                            </div>
                        </div>
                        <div className='grp_body'>
                            <div className='tab1  center_div'>
                                <img className='grp_profile_img' src={image.DEFAULT_PROFILE_IMAGE} />
                            </div>
                            <div className='tab2 center_div'>Dhruv Gopani</div>
                            <div className='tab3 center_div'>Total Managers</div>
                            <div className='tab4 center_div'>Total Brokers</div>
                            <div className='tab5 center_div'>
                                <Checkbox checked={true} onChange={() => { console.log("checkbox") }} />
                            </div>
                        </div>
                        <div className='grp_body'>
                            <div className='tab1  center_div'>
                                <img className='grp_profile_img' src={image.DEFAULT_PROFILE_IMAGE} />
                            </div>
                            <div className='tab2 center_div'>Dhruv Gopani</div>
                            <div className='tab3 center_div'>Total Managers</div>
                            <div className='tab4 center_div'>Total Brokers</div>
                            <div className='tab5 center_div'>
                                <Checkbox checked={true} onChange={() => { console.log("checkbox") }} />
                            </div>
                        </div>
                        <div className='grp_body'>
                            <div className='tab1  center_div'>
                                <img className='grp_profile_img' src={image.DEFAULT_PROFILE_IMAGE} />
                            </div>
                            <div className='tab2 center_div'>Dhruv Gopani</div>
                            <div className='tab3 center_div'>Total Managers</div>
                            <div className='tab4 center_div'>Total Brokers</div>
                            <div className='tab5 center_div'>
                                <Checkbox checked={true} onChange={() => { console.log("checkbox") }} />
                            </div>
                        </div>
                        <div className='grp_body'>
                            <div className='tab1  center_div'>
                                <img className='grp_profile_img' src={image.DEFAULT_PROFILE_IMAGE} />
                            </div>
                            <div className='tab2 center_div'>Dhruv Gopani</div>
                            <div className='tab3 center_div'>Total Managers</div>
                            <div className='tab4 center_div'>Total Brokers</div>
                            <div className='tab5 center_div'>
                                <Checkbox checked={true} onChange={() => { console.log("checkbox") }} />
                            </div>
                        </div>
                        <div className='grp_body'>
                            <div className='tab1  center_div'>
                                <img className='grp_profile_img' src={image.DEFAULT_PROFILE_IMAGE} />
                            </div>
                            <div className='tab2 center_div'>Dhruv Gopani</div>
                            <div className='tab3 center_div'>Total Managers</div>
                            <div className='tab4 center_div'>Total Brokers</div>
                            <div className='tab5 center_div'>
                                <Checkbox checked={true} onChange={() => { console.log("checkbox") }} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='select_broker_manager_container'>
                <div className='select_container'>
                    <div className='header_container'>
                        <div className='header_text'>Managers</div>
                        <AddButton onclick={() => console.log("clicl")} plusIcon={true} padding='10px 15px' buttonClass='add_btn' />
                    </div>
                    <div className='list_container'>
                        {managers.map(manager => (
                            <div className='profile_data'>
                                <div className='profile'>
                                    <div className='profile_image_container'>
                                        <img src={manager.profile_image ?? image.DEFAULT_PROFILE_IMAGE} className='profile_image' />
                                    </div>
                                    <div className='data_container'>
                                        <div className='profile_data_container'>
                                            <div className='profile_name'>{manager.name}</div>
                                            <div className='profile_contact'>{manager.country_code} {manager.contact}</div>

                                        </div>
                                        <div className='profile_project_container'>
                                            <div className='project_count'>{manager.projects}</div>
                                            <div className='project_text'>Projects</div>
                                        </div>
                                    </div>
                                    <div className='add_btn_container'>
                                        <CustomRadioButton onChange={(e) => console.log(e.target.value)} checked={true} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='select_container'>
                    <div className='header_container'>
                        <div className='header_text'>Brokers</div>
                        <AddButton onclick={() => console.log("clicl")} plusIcon={true} padding='10px 15px' buttonClass='add_btn' />
                    </div>
                    <div className='list_container'>
                        {managers.map(manager => (
                            <div className='profile_data'>
                                <div className='profile'>
                                    <div className='profile_image_container'>
                                        <img src={manager.profile_image ?? image.DEFAULT_PROFILE_IMAGE} className='profile_image' />
                                    </div>
                                    <div className='data_container'>
                                        <div className='profile_data_container'>
                                            <div className='profile_name'>{manager.name}</div>
                                            <div className='profile_contact'>{manager.country_code} {manager.contact}</div>

                                        </div>
                                        <div className='profile_project_container'>
                                            <div className='project_count'>{manager.projects}</div>
                                            <div className='project_text'>Projects</div>
                                        </div>
                                    </div>
                                    <div className='add_btn_container div_center'>
                                        <Checkbox checked={false} onChange={() => console.log("clicked")} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='div_center create_project_btn_container'>
                <button className='cancel_btn'>
                    Cancel
                </button>
                <button className='submit_btn'>
                    Submit
                </button>
            </div>
        </Container>
    )
}

export default CreateProjectModal

const Container = styled.div``

const ScreenContainer = styled.div`
  min-height: 700px;
  width: 100%;
`
//     < ScreenContainer >

//     <ProjectDescription
//         screen={screen}
//         title={GetScreenHeading(screen)}
//         handleNext={handleNext}
//         handlePrevious={handlePrevious}
//         onChangeData={onChangeData}
//         selectedThumbnail={selectedThumbnail}
//         projectDescription={projectDescription}
//     />

// {/* <ProjectAddress
//                             onChangeData={onChangeData}
//                             screen={screen}
//                             title={GetScreenHeading(screen)}
//                             projectAddress={projectAddress}
//                             handleNext={handleNext}
//                             handlePrevious={handlePrevious}
//                         />

//                         <TrainingVideos
//                             onChangeData={onChangeData}
//                             handleRemoveTrainingVideo={handleRemoveTrainingVideo}
//                             trainingVideos={trainingVideos}
//                             trainingVideoThumbnails={trainingVideoThumbnails}
//                             title={GetScreenHeading(screen)}
//                             handleNext={handleNext}
//                             handlePrevious={handlePrevious}
//                         />

//                         <CommonMedia
//                             title={GetScreenHeading(screen)}
//                             onChangeData={onChangeData}
//                             newProjectBrochure={newProjectBrochure}
//                             newSitePhotos={newSitePhotos}
//                             newSiteFlyers={newSiteFlyers}
//                             handlePrevious={handlePrevious}
//                             setNewSiteFlyers={setNewSiteFlyers}
//                             setNewProjectBrochure={setNewProjectBrochure}
//                             setNewSitePhotos={setNewSitePhotos}
//                             handleNext={handleNext}
//                         />

//                         <SelectedManagersScreen
//                             title={GetScreenHeading(screen)}
//                             onChangeData={onChangeData}
//                             handlePrevious={handlePrevious}
//                             handleNext={handleNext}
//                             selectedNewManagers={selectedNewManagers}
//                         /> */}

//                 </ScreenContainer >