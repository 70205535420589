import React, { useState } from 'react'
import { image } from '../../../app/utils/common/image'
import { useNavigate } from 'react-router-dom';

const SignUpScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRememberMe(event.target.checked);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleLoginChange = () => {
        navigate("/login")
    }

    return (
        <div className='AuthForm_main'>
            <div className='AuthForm_Card'>
                <img className='AuthPageLogo' src={image.LoginPageLogo} />
                <div className='signUpFormContainer'>
                    <div className='upperBox'></div>
                    <div className='auth_Form'>
                        <p className='auth_Form-Heading'>signup</p>
                        <div className="auth_form-Inputs_container">
                            <div className='auth_form-Inputs'>

                                <div className='email_div'>
                                    <label htmlFor="name">Name</label>
                                    <div className='input_field'>
                                        <img src={image.EmailLogo} alt="" />
                                        <input className='input' type="text" name="name" placeholder="enter name" />
                                    </div>
                                </div>

                                <div className='email_div'>
                                    <label htmlFor="email">Phone Number</label>
                                    <div className='input_field'>
                                        <img src={image.EmailLogo} alt="" />
                                        <input className='input' type="text" name="phone" placeholder="enter phone number" />
                                    </div>
                                </div>

                                <div className='password_div'>
                                    <label htmlFor="password">Password</label>
                                    <div className='input_field'>
                                        <img src={image.PasswordLogo} alt="" />
                                        <input className='input' type={showPassword ? "text" : "password"}
                                            name="password" placeholder="enter password" />
                                        <img
                                            src={image.showPasswordIcon}
                                            alt="show password icon"
                                            onClick={toggleShowPassword}
                                            className="togglePasswordVisibility"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='auth_form-Options'>
                                <label>
                                    <input className='checkbox' type="checkbox" name="rememberMe" checked={rememberMe}
                                        onChange={handleCheckboxChange} />
                                    I agree with terms and conditions and
                                    privacy policy
                                </label>
                            </div>
                            <div className='authButton_div'>
                                <button className='authButton'>signup</button>
                            </div>
                            <p className='auth_text'>Already have an account? <span onClick={handleLoginChange}> Login</span> </p>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SignUpScreen
