import { Tabs } from 'antd'
import React from 'react'
import VisitsList from './VisitsList';

const VisitTab = () => {

    const ScheduledTabHeader = (data: { number: any }) => (
        <div className='tab_selected'>
            Scheduled
            <div className='active_project_number'>{6}</div>
        </div>
    );

    const CompletedTabHeader = (data: { number: any }) => (
        <div className='tab_selected'>
            Completed
            <div className='completed_projects_number'>{9}</div>
        </div>
    );


    return (
        <div>
            <Tabs
                defaultActiveKey="1"
                tabBarStyle={{
                    borderBottom: '1px solid #C4C4C4', // Underline color for tabs
                }}
                items={[

                    {
                        key: '1',
                        label: <ScheduledTabHeader number={6} />,
                        // label: 'Scheduled',
                        children: <VisitsList isActive={true} />,
                    },
                    {
                        key: '2',
                        label: <CompletedTabHeader number={6} />,
                        children: <VisitsList isActive={true} />,
                    },
                ]}
            />

        </div>
    )
}

export default VisitTab
