import React from 'react'
import ManageGroupTable from '../../molecules/ManageGroupsComponent/ManageGroupsTable/ManageGroupsTable';
import './ManageGroups.scss'
import { Button } from 'antd';
import { image } from '../../../app/utils/common/image';
import { useNavigate } from 'react-router-dom';

const ManageGroups = () => {
    const navigate = useNavigate()
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='allManager'>
                    Manage Groups
                </div>
                <div className='button_container'>
                    <div className='btn'>
                        <img className='btn_icon' src={image.FILTER_ICON} />
                        <div>
                            Filter
                        </div>
                    </div>
                    <div className='btn' onClick={() => navigate('create')}>
                        <img className='btn_icon' src={image.ADD_ICON} />
                        <div>
                            Create
                        </div>
                    </div>
                </div>
            </div>
            <div className='manager_data_table'>
                <ManageGroupTable />
            </div>
        </div>
    )
}

export default ManageGroups