import React, { useEffect } from 'react'
import './App.css';
import AppRoutes from './app/routes/AppRoutes';
import { useAppDispatch } from './app/utils/hooks/reduxHook';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

function App() {

  return (
    <div className='App'>
      <AppRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;
