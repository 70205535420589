import React from 'react'
import { image } from '../../../app/utils/common/image'
import Card from '../../atoms/Card/Card'

let StatsData = [
  {
    value: "8",
    label: "Total Ongoing Sites",
    percentage: "+0.5%",
    icon: image.SITE_WHITE_ICON,
  },
  {
    value: "880",
    label: "Managers Worldwide",
    percentage: "+32%",
    icon: image.MANAGERS_WORLDWIDE_ICON
  },
  {
    value: "2,256",
    label: "Brokers Worldwide",
    percentage: "+26.5%",
    icon: image.BROKERS_WORLDWIDE_ICON
  },
  {
    value: "563",
    label: "Sites Completed",
    percentage: "+20.5%",
    icon: image.SITE_COMPLETED_ICON
  },

]

const HomepageStats = () => {

  return (
    <div className='stats_container'>
      <div className='stats_header'>
        Dashboard
      </div>
      <div className='stats_card_container'>
        {StatsData.map(stat => (
          <Card rotation={180} primaryBorderColor='#5f993700' secondaryBorderColor='white' >
            <div className='stats_card'>
              <div className='stats_data_container'>
                <div className='stats_number'>
                  {stat.value}
                </div>
                <div className='stats_label'>
                  {stat.label}
                </div>
                <div>
                  <span className="stats_increment_percentage">{stat.percentage} </span><span className='stats_span'>than last month</span>
                </div>
              </div>
              <div className='stats_icon_container'>
                <div className='stats_icon'>
                  <img className='stats_icon_image' src={stat.icon} />
                </div>
              </div>
            </div>
          </Card>
        ))}

      </div>
    </div>
  )
}

export default HomepageStats