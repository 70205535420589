import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import ProjectList from './ProjectList';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import { getAllActiveProjects } from '../../../redux/API/ProjectAPI';
import './SiteManagement.scss';

const ProjectsTabs: React.FC = () => {
    const { ActiveProjects, CompletedProjects } = useAppSelector(state => state.project)
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getAllActiveProjects('Active'))
        dispatch(getAllActiveProjects('Completed'))
    }, [dispatch]);

    const ActiveTabHeader = (data: { number: any }) => (
        <div className='tab_selected'>
            Active
            <div className='active_project_number'>{data.number}</div>
        </div>
    );

    const CompletedTabHeader = (data: { number: any }) => (
        <div className='tab_selected'>
            Completed
            <div className='completed_projects_number'>{data.number}</div>
        </div>
    );

    return (
        <div>
            <Tabs
                defaultActiveKey="1"
                tabBarStyle={{
                    borderBottom: '1px solid #C4C4C4', // Underline color for tabs
                }}
                items={[
                    {
                        label: <ActiveTabHeader number={ActiveProjects.length} />,
                        key: '1',
                        children: <ProjectList projects={ActiveProjects} isActive={true} />,
                    },
                    {
                        label: <CompletedTabHeader number={CompletedProjects.length} />,
                        key: '2',
                        children: <ProjectList projects={CompletedProjects} isActive={false} />,
                    },
                ]}
            />
        </div>
    )
}

export default ProjectsTabs