import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Logo from '../atoms/Logo/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import { sidebarOptions } from '../../dto/SidebarOptions';
import { checkPermission } from '../../app/utils/common/checkPermission';

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(sidebarOptions[0])
  const navigate = useNavigate();
  const location = useLocation()
  const currentPath = location.pathname;

  useEffect(() => {
    sidebarOptions.forEach((Option) => {
      if (Option.redirection === currentPath) {
        setActiveTab(Option)
      }
    })
  }, [currentPath])
  const handleNavigation = (clickedTab: any) => {
    setActiveTab(clickedTab)
    navigate(clickedTab.redirection)
    setIsOpen(false); // Close sidebar when navigating
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }
  const renderSidebarItem = (sidebarOption: any) => {
    if (sidebarOption.permission) {
      if (checkPermission(sidebarOption.permission, "read")) {
        return (
          <MenuItem onClick={() => handleNavigation(sidebarOption)} active={sidebarOption.key === activeTab.key}>
            <SidebarIconDiv>

              <SidebarIcon src={sidebarOption.key !== activeTab.key ? sidebarOption.icon : sidebarOption.whiteIcon} />
            </SidebarIconDiv>
            <SidebarText>

              {sidebarOption.key}
            </SidebarText>
          </MenuItem>
        )
      } else {
        return;
      }
    } else {
      return (
        <MenuItem onClick={() => handleNavigation(sidebarOption)} active={sidebarOption.key === activeTab.key}>
          <SidebarIconDiv>

            <SidebarIcon src={sidebarOption.key !== activeTab.key ? sidebarOption.icon : sidebarOption.whiteIcon} />
          </SidebarIconDiv>
          <SidebarText>

            {sidebarOption.key}
          </SidebarText>
        </MenuItem>
      )
    }
  }
  return (
    <Container>
      <LogoSection>
        <Logo height={140} width={140} />
        <Hamburger onClick={toggleMenu} isOpen={isOpen}>
          <div />
          <div />
          <div />
        </Hamburger>
      </LogoSection>
      <MenuSection isOpen={isOpen}>
        {sidebarOptions.map(option => (
          renderSidebarItem(option)


        ))}
      </MenuSection>
    </Container>
  );
}

export default Sidebar;

const Container = styled.div`
  width: 20%;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  
  color:black;
  @media (max-width: 768px) {
    width: 95%;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 1000;
  }
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width:768px){
    justify-content: space-between;
  }
`;

const Hamburger = styled.div<{ isOpen: boolean }>`
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  div {
    width: 100%;
    height: 3px;
    background-color: green;
    border-radius: 2px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &:nth-child(1) {
      transform: ${({ isOpen }) => isOpen ? 'rotate(45deg) translate(5px, 5px)' : 'none'};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => isOpen ? 0 : 1};
    }

    &:nth-child(3) {
  
      transform: ${({ isOpen }) => isOpen ? 'rotate(-44deg) translate(4px, -6px)' : 'none'};
    }
  }

  @media(min-width: 768px) {
    display: none;
  }
`;

const slideIn = keyframes`
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
`;

const slideOut = keyframes`
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
`;

const MenuSection = styled.div<{ isOpen: boolean, }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 110vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius:30px;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
  transform: ${({ isOpen }) => isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.3s ease-in-out;
  animation: ${({ isOpen }) => isOpen ? slideIn : slideOut} 0.3s ease-in-out;
  z-index: 1000;

  @media(min-width: 768px) {
    position: relative;
    width: auto;
    height: auto;
    transform: none;
    animation: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top:30px;
    width:100%;
    font-weight:600;
    padding: 0;
    z-index: 0;
  }
`;

const MenuItem = styled.div<{ active: boolean }>`
  padding: 15px 0;
  color: ${props => props.active ? 'white' : "black"};
  background-color: ${props => props.active ? '#5F9937' : "white"};
  box-shadow: ${props => props.active ? '0px 4px 18px 0px #5F993766' : null};
  border-radius:11px;
 
width:100%;
display:flex;
align-items:center;
justify-content:space-between;
margin:15px 0px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
 width: 100%;
    text-align: center;
  &:hover {
    transform: scale(1.01);
  }

  @media(min-width: 768px) {
    padding: 10px 0px;
    font-size: 22px;
   
  }
`;

const SidebarIconDiv = styled.div`
width:20%;
display:flex;
align-items:center;
justify-content:center;
`;
const SidebarIcon = styled.img`
width:30px;
height:30px;
`

const SidebarText = styled.p`
 text-decoration: none;
  font-size: 18px;
  font-family: "Lufga";
font-weight: 400;
text-align:left;
width:80%;
margin:0;
`;
