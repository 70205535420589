import React, { ChangeEvent } from 'react';
import './Input.scss';

interface InputProps {
    label?: string;
    placeholder: string;
    type: string;
    id: string;
    name: string;
    value: string | number | any;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    classNameInput?: string;
    disabled?: boolean;
    style?: any;
    showIcon?: boolean;
    iconSrc?: string
}

const Input: React.FC<InputProps> = (props) => {
    const { label, placeholder, type, id, name, value, onChange, className, classNameInput, disabled, style, iconSrc, showIcon } = props;

    return (
        <div className={className}>
            <div className="search_bar">
                <div className="form-label">{label}</div>
                <div className='inputWrapper_input'>
                    {showIcon && iconSrc && <img src={iconSrc} alt={`${label} icon`} className="inputIcon" />}
                    <input
                        className={`${classNameInput} search-input`}
                        placeholder={placeholder}
                        name={name}
                        type={type}
                        id={id}
                        disabled={disabled}
                        value={value}
                        style={style}
                        onChange={onChange}
                        onWheel={(e) => e.currentTarget.blur()} // TypeScript needs to specify `currentTarget`
                    />
                </div>
            </div>
        </div>
    );
};

export default Input;