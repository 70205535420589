import { createSlice } from "@reduxjs/toolkit";
import { projectReducer } from "../Reducer/Project/ProjectReducer";
import { ProjectListType } from "../../dto/Projects.dto";


export interface ProjectData {
    id: number;
    name: string;
    url: string;
}


export interface ProjectState {
    ActiveProjects: ProjectListType[];
    CompletedProjects: ProjectListType[];
    CurrentProject: null | { id: number; name: string; url: string };
    error: string | null;
    loading: boolean;
}



const initialState: ProjectState = {
    loading: false,
    ActiveProjects: [],
    CompletedProjects: [],
    CurrentProject: null,
    error: null,


};

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        projectReducer(builder)
    }

});
export const selectAuthStatus = (state: any) => state.auth?.status;
export default projectSlice.reducer;