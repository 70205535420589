// src/components/atoms/Checkbox.tsx
import React from 'react';
import './Checkbox.scss';
import { Checkbox as CheckboxANTD } from 'antd'

interface CheckboxProps {
    checked: boolean;
    onChange?: () => void;
    onClick?: () => void;
    // onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

    id?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, id, onClick }) => {
    return (
        <CheckboxANTD
            id={id}
            type="checkbox"
            checked={checked}
            onChange={onChange}
            onClick={onClick}
            className="checkbox"
        />
    );
};

export default Checkbox;
