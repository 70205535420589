import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { BrokerSliceState } from "../../Slices/brokerSlice";
import {
  createBrokerAPI,
  deleteBrokerAPI,
  getAllBrokers,
  getCPsManagerAPI,
  updateBrokerAPI,
} from "../../API/BrokerAPI";
import { PaginationType } from "../../../dto/Pagination.dto";
import { BrokerType } from "../../../dto/Broker.dto";
import { showErrorToast } from "../../../app/utils/common/Toast";
import { ManagerType } from "../../../dto/Manager.dto";

export const BrokerReducer = (
  builder: ActionReducerMapBuilder<BrokerSliceState>
) => {
  builder
    //get brokers
    .addCase(getAllBrokers.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getAllBrokers.fulfilled,
      (
        state,
        action: PayloadAction<{
          cp_data: BrokerType[];
          pagination: PaginationType;
        }>
      ) => {
        state.loading = false;
        state.brokers = action.payload.cp_data;
        state.pagination = action.payload.pagination;
      }
    )
    .addCase(getAllBrokers.rejected, (state, action: PayloadAction<any>) => {
      console.log("error");
      state.loading = false;
    })

    .addCase(createBrokerAPI.pending, (state) => {
      console.log("state :", state);
      state.loading = true;
    })

    .addCase(
      createBrokerAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          broker_data: BrokerType[];
          pagination: PaginationType;
        }>
      ) => {
        state.loading = false;
        state.brokers = [];
      }
    )
    .addCase(createBrokerAPI.rejected, (state, action: PayloadAction<any>) => {
      console.log("error");
      state.loading = false;
      if (action.payload.message) {
        showErrorToast(action.payload.message);
      } else {
        showErrorToast("Error in broker creation.");
      }
    })

    //update
    .addCase(updateBrokerAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      updateBrokerAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          broker_data: BrokerType[];
          pagination: PaginationType;
        }>
      ) => {
        state.loading = false;
        state.brokers = [];
      }
    )
    .addCase(updateBrokerAPI.rejected, (state, action: PayloadAction<any>) => {
      console.log("error");
      state.loading = false;
    })

    //delete
    .addCase(deleteBrokerAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      deleteBrokerAPI.fulfilled,
      (
        state,
        action: PayloadAction<{
          broker_data: BrokerType[];
          pagination: PaginationType;
        }>
      ) => {
        state.loading = false;
        state.brokers = [];
      }
    )

    // get managers for a specific broker (cpId)

    .addCase(deleteBrokerAPI.rejected, (state, action: PayloadAction<any>) => {
      console.log("error");
      state.loading = false;
    })

    .addCase(getCPsManagerAPI.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getCPsManagerAPI.fulfilled,
      (state, action: PayloadAction<ManagerType[]>) => {
        state.loading = false;
        state.managers = action.payload;
      }
    )
    .addCase(getCPsManagerAPI.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      showErrorToast("Failed to load managers.");
    });
};
