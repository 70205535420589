import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { projectReducer } from "../Reducer/Project/ProjectReducer";
import { ProjectListType } from "../../dto/Projects.dto";

export interface PermissionType {
  module_id: number;
  module_name: string;
  module_key: string[];
}

export interface PermissionSliceState {
  loading: boolean;
  permissions: PermissionType[];
}

const initialState: PermissionSliceState = {
  loading: false,
  permissions: [],
};

const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    addPermission(state, action: PayloadAction<any>) {
      state.permissions = action.payload;
      console.log("state.permissions :>> ", state.permissions);
    },
  },
  extraReducers: (builder) => {},
});

export const { addPermission } = permissionSlice.actions;
export default permissionSlice.reducer;
