import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook'
import { PostListType } from '../../../dto/Projects.dto';
import { getImageURL } from '../../../app/utils/common/getImageUrl';
import Card from '../../atoms/Card/Card';
import { image } from '../../../app/utils/common/image';
import Button from '../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';

interface PostListProps {
    projects: PostListType[],
    isActive: boolean
}

const PostList: React.FC<PostListProps> = ({ projects, isActive = false }) => {

    const { loading } = useAppSelector(state => state.project)
    const navigate = useNavigate()
    return (
        <div className={isActive ? 'active_projects_container' : 'completed_project_container'}>
            {loading ? <div>Loading...</div> :
                <>
                    {projects.map(project => (
                        <Card
                            customClass='project_card'
                            primaryBorderColor={!isActive ? "#F7681C" : "#5f9937"}
                        >
                            <div onClick={() => navigate(`/dashboard/post-requests/${project.project_id}`)}
                                className='post-card'>
                                <div style={{ display: "contents" }}>
                                    <div className='profile_data'>
                                        <div className='profile'>
                                            <div className='profile_image_container'>
                                                <img src={image.DEFAULT_PROFILE_IMAGE} className='profile_image' />
                                            </div>
                                            <div className='data_container'>
                                                <div className='profile_data_container'>
                                                    <div className='profile_name'>Mark Lewis</div>
                                                    <div className='profile_contact'>+91 7675847384</div>
                                                </div>
                                                <div className='profile_project_container'>
                                                    <div className='project_text'>2h ago</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Post image Only */}
                                    <div className='post-thumbnail-container'>
                                        <img className='project-card-thumbnail' src={getImageURL(project.url)} />
                                    </div>

                                    {/* Post description Only */}
                                    <div className='project-card-description'>
                                        Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry. Lorem Ipsum has been the
                                        industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and
                                        scrambled it to make a type specimen book. It has
                                        survived not only five centuries, but also the leap into
                                        electronic typesetting, remaining essentially
                                        unchanged. It was popularised in the 1960s with the
                                        release of Letraset sheets containing Lorem Ipsum
                                        passages, and more recently with desktop publishing
                                        software like Aldus PageMaker including versions
                                        of Lorem Ipsum.
                                        typesetting, remaining essentially
                                        unchanged. It was popularised in the 1960s with the
                                        release of Letraset sheets containing Lorem Ipsum
                                        passages, and more recently with desktop publishing
                                        software like
                                    </div>
                                </div>

                                {/* When post is in accepted list please hide this component */}
                                <div className='reject-accept-btn'>
                                    <Button
                                        buttonTitle="Reject"
                                        onClick={() => { }}
                                        className={'RejectButton'}
                                    />
                                    <Button
                                        buttonTitle="Accept"
                                        onClick={() => { }}
                                        className={'RejectButton AcceptButton'}
                                    />
                                </div>
                            </div>
                        </Card>
                    ))}
                </>
            }
        </div >
    )
}

export default PostList