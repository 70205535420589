import React, { FC } from 'react'
import AddButton from '../../../../atoms/CustomButton/AddButton/AddButton'
import { image } from '../../../../../app/utils/common/image'
import ActionIcon from '../../../../atoms/ActionIcon/ActionIcon';
import './GroupMBComponent.scss';
import { getImageURL } from '../../../../../app/utils/common/getImageUrl';
import ErrorText from '../../../../atoms/Error/error';

interface GroupMBComponentProps {
    managers: any[],
    handleManagerSelect: (manager_id: string) => void,
    selectedManagerIds: string[];
    managerUnderGroups: any[],
    groupLoading: boolean,
    loadingManager: boolean,
    type: any;
    showError: boolean
}

const GroupMBComponent: FC<GroupMBComponentProps> = (props) => {
    const { managers, handleManagerSelect, selectedManagerIds, managerUnderGroups, groupLoading, loadingManager, type, showError = false } = props;
    const loadingState = type === 'create' ? loadingManager : groupLoading;
    return (
        <div className='group-main-manager-container'>
            <div className='group-manager-container'>
                <div className='manager-header-flex'>
                    <div className='group-manager-heading'>Managers</div>
                    <AddButton onclick={() => console.log("clicl")} plusIcon={true} buttonClass='add_btn' />
                </div>

                {!loadingState ? <div className='profile_data'>
                    {(managerUnderGroups.length ? managerUnderGroups : managers).map((manager) => {
                        return (<div
                            key={manager.manager_id}
                            className={`profile ${selectedManagerIds.includes(manager.manager_id) ? 'selected' : ''}`}
                            onClick={() => handleManagerSelect(manager.manager_id)}>
                            <div className='profile_image_container'>
                                <img src={manager.profile_image ? getImageURL(manager.profile_image) : image.DEFAULT_PROFILE_IMAGE} className='profile_image' />
                            </div>
                            <div className='data_container'>
                                <div className='profile_data_container'>
                                    <div className='profile_name'>{manager.name}</div>

                                </div>
                                <div className='profile-end-flex'>
                                    <div className='profile_project_container'>
                                        <div className='project_text'>{manager.country_code} {manager.phone_number}</div>
                                    </div>
                                    <ActionIcon />
                                </div>
                            </div>
                        </div>)
                    })}
                    {showError && selectedManagerIds.length === 0 && <ErrorText message='Please select manager' />}
                </div> : <div>loadingManagers</div>}

            </div>
            <div className='group-manager-container'>
                <div className='manager-header-flex'>
                    <div className='group-manager-heading'>Brokers</div>
                    <AddButton onclick={() => console.log("clicl")} plusIcon={true} buttonClass='add_btn' />
                </div>
                <div className='profile_data'>
                    <div className='profile'>
                        <div className='profile_image_container'>
                            <img src={image.DEFAULT_PROFILE_IMAGE} className='profile_image' />
                        </div>
                        <div className='data_container'>
                            <div className='profile_data_container'>
                                <div className='profile_name'>Mark Lewis</div>
                                <div className='profile_contact'>mark@gmail.com</div>

                            </div>
                            <div className='profile-end-flex'>
                                <div className='profile_project_container'>
                                    <div className='project_text'>+91 7675847384</div>
                                </div>
                                <ActionIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroupMBComponent