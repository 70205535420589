import React, { useState } from 'react'
import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import "./ManagerListTable.scss"
import { image } from '../../../../app/utils/common/image';
import TableComponent from '../../../atoms/TableComponent/Tablecomponent';
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon';
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import { useNavigate } from 'react-router-dom';


const ActionButtonContainer = () => {
    const [isMenuOpen, seIsMenuOpen] = useState(false);
    const options = [{
        name: "Edit",
        onClick: () => {
            console.log("edit click");
        }
    },
    {
        name: "Delete",
        onClick: () => {
            console.log("delete click");
        }
    }
    ]
    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {
                isMenuOpen && <OptionMenu options={options} left={"-100px"} onOutSideClick={() => seIsMenuOpen(false)} />
            }
        </div>
    )
}

const ManagerListTable: React.FC = () => {
    const navigate = useNavigate();
    const columns: TableColumnsType = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            render: (text, record) => (
                <img
                    src={record.profile}
                    alt="Profile"
                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Broker Allocated',
            dataIndex: 'brokerAllocated',
            render: (text, record) => (
                <div className='broker_alloted_table_value'>
                    <div className='text'>22</div>
                    <div className='add_btn'>
                        <img src={image.ADD_PLUS_WHITE_ICON} className='plus_img' />
                        Add
                    </div>
                </div>
            )
        },
        {
            title: 'Sites',
            dataIndex: 'sites',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (

                <ActionButtonContainer />
            ),
        },
    ];


    const dataSource = Array.from({ length: 40 }).map((_, i) => ({
        key: i,
        profile: image.DEFAULT_PROFILE_IMAGE,
        name: `Edward King ${i}`,
        phoneNumber: 123456,
        sites: 20,
    }));

    const [currentPage, setCurrentPage] = useState(1);
    const pageNumbers: any[] = [];
    const [pagination, setPagination] = useState({
        totalData: dataSource.length,
        currentPage: 1,
    });
    const pageSize = 7;
    const totalPages = Math.ceil(dataSource.length / pageSize);

    // Get data for the current page
    const currentData = dataSource.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div className='manager_dataTable' style={{ marginTop: "0" }}>
            <div className='button_container'>
                <div className='btn'>
                    <img className='btn_icon' src={image.FILTER_ICON} />
                    <div>
                        Filter
                    </div>
                </div>
                <div className='btn' onClick={() => console.log("called")}>
                    <img className='btn_icon' src={image.ADD_ICON} />
                    <div>
                        Add
                    </div>
                </div>
            </div>
            <div>

                <TableComponent columns={columns}
                    dataSource={currentData}
                    pageNumbers={pageNumbers}
                    handlePageLink={handlePageChange}
                    totalProfile={pagination.totalData}
                    prevPage={() => handlePageChange(currentPage - 1)}
                    nextPage={() => handlePageChange(currentPage + 1)}
                    activePage={currentPage}
                    currentTotal={Math.min(currentPage * pageSize, dataSource.length)} />
            </div>
        </div>
    )


}

export default ManagerListTable
