import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";

export const uploadFile = async (files: File[], folder_name: string) => {
  let formData = new FormData();
  formData.append("folder_name", folder_name);
  files.forEach((file) => {
    formData.append("files", file);
  });
  const response = await apiClient.post(ApiConstants.UPLOAD_FILE, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
export const deleteMedia = async (data: { image_path: string[] }) => {


  const response = await apiClient.post(ApiConstants.UPLOAD_FILE, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const getAllActiveProjects = createAsyncThunk(
  "project/getAllActiveProjects",
  async (data: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`${ApiConstants.GETALL_PROJECT}?status=${data}`);

      return {
        type: data,
        response: response.data
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to get projects");
    }
  }
);
