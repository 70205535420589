import React from 'react';
import './Button.scss'

interface ButtonProps {
    onClick: any;
    buttonTitle: string,
    className?: string
}

const Button: React.FC<ButtonProps> = (props) => {
    const { onClick, buttonTitle, className } = props
    return (
        <button className={className} onClick={onClick}>
            <span>{buttonTitle}</span>
        </button>
    )
}

export default Button