import React, { FC } from 'react';
import './Loader.scss'

const Loader: FC = () => {
    return (
        <div className="loader-container">
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader
