import React from 'react';
import './OptionMenu.scss';
import OutsideClickHandler from 'react-outside-click-handler';

interface OptionMenuPropsType {
    left: string;
    options: {
        name: string;
        onClick: () => void;

    }[],
    top?: string;
    onOutSideClick: Function
}

const OptionMenu: React.FC<OptionMenuPropsType> = ({
    left,
    options,
    top,
    onOutSideClick
}) => {
    return (
        <OutsideClickHandler onOutsideClick={() => onOutSideClick()}>
            <div className='option_menu' style={{ left: `${left}`, top: `${top}` }}>
                {options.map(option => (
                    <div className='menu_item' onClick={() => option.onClick()}>{option.name}</div>
                ))}
            </div>
        </OutsideClickHandler>
    )
}

export default OptionMenu