import React from 'react'
import Input from '../../../atoms/Input/Input'
import { image } from '../../../../app/utils/common/image'

const DetailsHeader = () => {
    return (
        <div className='group-container broker-container'>
            <div style={{ position: "relative" }}>
                <div>
                    <img src='https://i.pravatar.cc/150?img=3' className='broker-profile' />
                    <img src={image.CAMERA_PHOTO} alt="camera icon" className="broker-camera_image" />
                </div>
            </div>
            <div className='broker-main-container'>
                <div className='group-name-container'>
                    <div className='group-photo'>Name</div>
                    <Input
                        placeholder="Enter Name"
                        type="text"
                        name="broker Name"
                        id="brokerName"
                        value={''}
                        onChange={() => { }}
                        iconSrc={image.PhoneLogo}
                        showIcon={true}
                    />
                </div>
                <div className='group-name-container'>
                    <div className='group-photo'>Email ID</div>
                    <Input
                        placeholder="Enter Email"
                        type="email"
                        name="broker Email"
                        id="brokerEmail"
                        value={''}
                        onChange={() => { }}
                        iconSrc={image.EmailLogo}
                        showIcon={true}
                    />
                </div>
                <div className='group-name-container'>
                    <div className='group-photo'>Phone Number</div>
                    <Input
                        placeholder="Enter Phone Number"
                        type="number"
                        name="broker number"
                        id="brokerNumber"
                        value={''}
                        onChange={() => { }}
                        iconSrc={image.PhoneLogo}
                        showIcon={true}
                    />
                </div>
            </div>

        </div>
    )
}

export default DetailsHeader;