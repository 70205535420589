import React, { FC, useEffect, useState } from 'react';
import type { TableColumnsType } from 'antd';
import { image } from '../../../../app/utils/common/image';
import Button from '../../../atoms/Button/Button';
import TableComponent from '../../../atoms/TableComponent/Tablecomponent';
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon';
import { useNavigate } from 'react-router-dom';
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import { confirmModalProps } from '../../../../dto/confirm';
import { GroupType } from '../../../../dto/Groups.dto';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { getAllGroups } from '../../../../redux/API/GroupApi';
import { getImageURL } from '../../../../app/utils/common/getImageUrl';
import { setCurrentGroup, setGroupPagination } from '../../../../redux/Slices/groupSlice';

const ActionButtonContainer: FC<{ Group: any }> = ({ Group }) => {
    const [isMenuOpen, seIsMenuOpen] = useState(false);
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const options = [{
        name: "Details",
        onClick: () => {
            dispatch(setCurrentGroup(Group));
            navigate("details")
        }
    }, {
        name: "Edit",
        onClick: () => {
            dispatch(setCurrentGroup(Group));
            navigate("edit")
        }
    },
    {
        name: "Remove",
        onClick: () => {
            setRemoveModal(true)
        }
    }
    ];

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to delete this group?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Delete', 'Cancel'],
        onPressPositive: () => { },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.REMOVE_GROUP_ICON
    };

    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {isMenuOpen && <OptionMenu options={options} left={"-100px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}

const ManageGroupTable: FC = () => {
    const [groupData, setGroupData] = useState<GroupType[]>([])
    const navigate = useNavigate()
    const { groups, loading, groupPagination } = useAppSelector(state => state.group);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getAllGroups({ page: groupPagination?.current_page }))
    }, [groupPagination?.current_page]);

    useEffect(() => {
        setGroupData(groups);
    }, [groups]);

    const columns: TableColumnsType = [
        {
            title: 'Group Photo',
            dataIndex: 'Photo',
            render: (text, record) => {
                return (
                    <img
                        src={record.photo ? getImageURL(record.photo) : image.DEFAULT_PROFILE_IMAGE}
                        alt="Profile"
                        className='reg-profile'
                        onClick={() => { navigate('create') }}
                    />
                )
            },
        },
        {
            title: 'Group Name',
            dataIndex: 'name',
            render: (text, record) => (
                <div onClick={() => navigate(`${record.group_id}`)} style={{ cursor: "pointer" }}>
                    {record.name}
                </div>
            )
        },
        {
            title: 'Managers',
            dataIndex: 'managers',
            render: (text, record) => {
                const MAX_VISIBLE_IMAGES = 4; // Limit of visible images
                // const images = record?.managers || []; // Fallback if record.managers is undefined
                const images = ['https://i.pravatar.cc/150?img=3',
                    image.DEFAULT_PROFILE_IMAGE,
                    image.DEFAULT_PROFILE_IMAGE,
                    image.DEFAULT_PROFILE_IMAGE,
                    image.DEFAULT_PROFILE_IMAGE,
                    image.DEFAULT_PROFILE_IMAGE,
                    image.DEFAULT_PROFILE_IMAGE,] //this is constant images data please remove this images and use commented images when api has been implemented

                return (
                    <div className='group_list_container'>
                        {images?.slice(0, MAX_VISIBLE_IMAGES).map((imgSrc: string, index: number) => (
                            <img key={index} src={imgSrc} alt={`Member ${index + 1}`} />
                        ))}
                        {images?.length > MAX_VISIBLE_IMAGES && (
                            <div className="total_members_class">
                                +{images.length - MAX_VISIBLE_IMAGES}
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Brokers',
            dataIndex: 'brokers',
            render: (text, record) => {
                const MAX_VISIBLE_IMAGES = 4; // Limit of visible images
                // const images = record?.managers || []; // Fallback if record.managers is undefined
                const images = ['https://i.pravatar.cc/150?img=3',
                    image.DEFAULT_PROFILE_IMAGE,
                    image.DEFAULT_PROFILE_IMAGE,
                    image.DEFAULT_PROFILE_IMAGE,
                    image.DEFAULT_PROFILE_IMAGE,
                    image.DEFAULT_PROFILE_IMAGE,
                    image.DEFAULT_PROFILE_IMAGE,] //this is constant images data please remove this images and use commented images when api has been implemented

                return (
                    <div className='group_list_container'>
                        {images?.slice(0, MAX_VISIBLE_IMAGES).map((imgSrc: string, index: number) => (
                            <img key={index} src={imgSrc} alt={`Member ${index + 1}`} />
                        ))}
                        {images?.length > MAX_VISIBLE_IMAGES && (
                            <div className="total_members_class">
                                +{images.length - MAX_VISIBLE_IMAGES}
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <ActionButtonContainer Group={record} />
            ),
        },
    ];

    const handlePageChange = (pageNumber: number) => {
        if (!loading) {
            let tempData = { ...groupPagination };
            tempData.current_page = pageNumber
            dispatch(setGroupPagination(tempData));
        }
    };
    return (
        <div className='registration_dataTable'>

            {!loading ? <TableComponent columns={columns}
                dataSource={groupData}
                loading={loading}
                pageNumbers={Array.from({ length: groupPagination?.total_pages }, (_, index) => index + 1)}
                handlePageLink={handlePageChange}
                isPagination={groupPagination?.total_pages > 1}
                totalProfile={groupPagination?.total_data}
                prevPage={() => handlePageChange(groupPagination?.current_page - 1)}
                nextPage={() => handlePageChange(groupPagination?.current_page)}
                activePage={groupPagination?.current_page}
                currentTotal={Math.min(groupPagination?.current_page * groupPagination?.page_size, groupData?.length)} />
                : <div
                    style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "250px" }}>
                    ...loading
                </div>
            }
        </div>
    );
};

export default ManageGroupTable;