import React, { useState } from 'react';
import { Carousel } from 'antd';
import "./SingleSiteDetails.scss"
import { image } from '../../../../app/utils/common/image';
import Modal from '../../../atoms/Modal/Modal';

const SingleSiteDetails = () => {
  const [addIncentiveModal, setAddIncentiveModal] = useState(false);
  return (
    <div className='single_site_details'>
      <div className='left_section'>
        <div className='carosaul_container'>
          <Carousel autoplay autoplaySpeed={3000} >

            <div >
              <div className='carosaul_image_container'>
                <img src={image.BUILDING_IMAGE} className='carosaul_image' />
              </div>
            </div>
            <div >
              <div className='carosaul_image_container'>
                <img src={image.BUILDING_IMAGE} className='carosaul_image' />
              </div>
            </div>
            <div >
              <div className='carosaul_image_container'>
                <img src={image.BUILDING_IMAGE} className='carosaul_image' />
              </div>
            </div>
            <div >
              <div className='carosaul_image_container'>
                <img src={image.BUILDING_IMAGE} className='carosaul_image' />
              </div>
            </div>

          </Carousel>
        </div>
        <div className='pdf-container'>
          <div className='pdf-div'>
            <img src={image.PDF_PLACEHOLDER_IMAGE} className='pdf-img' />
            <div className='pdf_name'>123.pdf</div>
          </div>
          <div className='pdf-div'>
            <img src={image.PDF_PLACEHOLDER_IMAGE} className='pdf-img' />
            <div className='pdf_name'>123.pdf</div>
          </div>
          <div className='pdf-div'>
            <img src={image.PDF_PLACEHOLDER_IMAGE} className='pdf-img' />
            <div className='pdf_name'>123.pdf</div>
          </div>
          <div className='pdf-div'>
            <img src={image.PDF_PLACEHOLDER_IMAGE} className='pdf-img' />
            <div className='pdf_name'>123.pdf</div>
          </div>
          <div className='pdf-div'>
            <img src={image.PDF_PLACEHOLDER_IMAGE} className='pdf-img' />
            <div className='pdf_name'>123.pdf</div>
          </div>
          <div className='pdf-div'>
            <img src={image.PDF_PLACEHOLDER_IMAGE} className='pdf-img' />
            <div className='pdf_name'>123.pdf</div>
          </div>
        </div>
      </div>
      <div className='right_section'>
        <div className='upper_section'>
          <div className='project_data'>
            <div className='title'>Patil's Villa & Farm</div>
            <div className='address'>Sagvadi Road, Nanpura, Surat, Gujarat - 395006</div>
            <div className='project_desc'>Lorem Ipsum is simply dummy text of the printing and type
              setting industry. Lorem Ipsum has been the industry's
              standard dummy text ever since the 1500s, when an unknown
              printer took a galley of type and scrambled it to make a type
              specimen book. It has survived not only five centuries, but also
              the leap into electronic type setting.</div>
          </div>
          <div className='project_stats'>
            <div className='price'>
              <div className='price_text'>₹1.2 Cr</div>
              <div className='sub_text'>Unit Price</div>
            </div>
            <div className='incentive_container' onClick={(e) => setAddIncentiveModal(true)}>
              {/* <div >
                <span className='points'>25.5K</span>
                <span className='points_text'>points</span>
              </div>
              <div className='sub_text'>Incentive</div>
              <img className='edit_icon' src={image.EDIT_ICON} /> */}
              <img src={image.ADD_INCENTIVE_ICON} className='addIcon' />
              <div className='sub_text'>Add Incentive</div>
            </div>
          </div>
        </div>
        <div className='lower_section'>
          <div className='site_flyer'>
            <img src={image.BUILDING_IMAGE} />
          </div>
        </div>
      </div>

      {
        addIncentiveModal &&
        <Modal>
          <div className='add_incentive_modal'>
            <div className='center_div incentive_content'>
              <div className='text'>Add Incentive Points</div>
              <div className='input_container'>
                <input type="number" />
              </div>
              <div className='btn_container'>
                <button className='cancel_btn' onClick={(e) => setAddIncentiveModal(false)}>
                  Cancel
                </button>
                <button className='submit_btn'>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal>
      }
    </div>
  )
}

export default SingleSiteDetails