import React, { useEffect, useRef, useState } from 'react'
import { image } from '../../../app/utils/common/image'
import Card from '../../atoms/Card/Card';



const ManagerDataComponent = () => {
    const [dropDown, setDropDown] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target as Node)
        ) {
            setDropDown(false);
        }
    };
    useEffect(() => {
        if (dropDown) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [dropDown])

    return (
        <div className='user_data_container'>
            <div className='user_profile_container'>
                <div className='profile_image_container'>
                    <img className='profile_image' src={image.DEFAULT_PROFILE_IMAGE} />
                </div>
                <div className='profile_data'>
                    <div className='user_name'>
                        Dhruv Gopani
                    </div>
                    <div className='sub_data'>
                        +91 8849927290
                    </div>
                </div>
                <div className='data_number'>
                    50 Sites
                </div>
            </div>
            <div className='action_button_container' >
                <div className='dot_container' onClick={(e) => {
                    e.stopPropagation();
                    setDropDown(!dropDown);
                }}>

                    <div className='action_dot'></div>
                    <div className='action_dot'></div>
                    <div className='action_dot'></div>
                </div>
                {
                    dropDown && <div className='action_dropdown' ref={menuRef}></div>
                }
            </div>
        </div>
    )
}


const ManagerList = () => {
    return (
        <Card>
            <div className='top_managers_and_brokers_list_container'>
                <ManagerDataComponent />
            </div>
        </Card>
    )
}

export default ManagerList