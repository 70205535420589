import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook'
import { ProjectListType } from '../../../dto/Projects.dto';
import { getImageURL } from '../../../app/utils/common/getImageUrl';
import Card from '../../atoms/Card/Card';
import { image } from '../../../app/utils/common/image';
import { useNavigate } from 'react-router-dom';

interface ProjectListProps {
    projects: ProjectListType[],
    isActive: boolean
}

const ProjectList: React.FC<ProjectListProps> = ({ projects, isActive = false }) => {

    const { loading } = useAppSelector(state => state.project);
    const navigate = useNavigate()
    const MAX_VISIBLE_IMAGES = 3; // Limit of visible images
    // const images = record?.managers || []; // Fallback if record.managers is undefined
    const images = ['https://i.pravatar.cc/150?img=3',
        image.DEFAULT_PROFILE_IMAGE,
        image.DEFAULT_PROFILE_IMAGE,
        image.DEFAULT_PROFILE_IMAGE,
        image.DEFAULT_PROFILE_IMAGE,
        image.DEFAULT_PROFILE_IMAGE,
        image.DEFAULT_PROFILE_IMAGE,]
    return (
        <div className={isActive ? 'active_projects_container' : 'completed_project_container'}>
            {loading ? <div>Loading...</div> :
                <>
                    {projects.map(project => (
                        <Card customClass='project_card' isGreen={isActive}>

                            <div className='post-card site-card' onClick={() => navigate("/dashboard/site-management/site/id")}>
                                <div style={{ display: "contents" }}>

                                    <div className='post-thumbnail-container'>
                                        <img className='project-card-thumbnail' src={image.BUILDING_IMAGE} />
                                    </div>
                                    <div className='site-visited-title'>Surat Textile Market</div>
                                    <div className='site-visited-desc'>Adajan Patiya, Adajan, Surat. 395006</div>
                                    <div className='site-visited-group'>
                                        <div className='group_list_container total-list-site'>
                                            {images?.slice(0, MAX_VISIBLE_IMAGES).map((imgSrc: string, index: number) => (
                                                <img key={index} src={imgSrc} alt={`Member ${index + 1}`} />
                                            ))}
                                            {images?.length > MAX_VISIBLE_IMAGES && (
                                                <div className="total_members_class total-member-site">
                                                    +{images.length - MAX_VISIBLE_IMAGES}
                                                </div>
                                            )}
                                        </div>
                                        <div className='site-visited-manager'>Managers & Brokers</div>
                                    </div>


                                </div>


                            </div>
                        </Card>
                    ))}
                </>
            }
        </div>
    )
}

export default ProjectList