import axios, { AxiosError, InternalAxiosRequestConfig, AxiosResponse, AxiosRequestConfig } from 'axios';
import { refreshAccessToken } from '../../redux/API/UserAPI';
import { BASE_URL } from './axiosInstance';
import { clearToken, setAccessToken, setToken } from '../../redux/Slices/authSlice';

const apiClient = axios.create({
    baseURL: BASE_URL,
});

const getStore = () => {
    const store = require('../../redux/store').default;

    return store;
};

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};


apiClient.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
        let store = getStore();
        let state = store.getState();
        const accessToken = state.auth.access_token ?? localStorage.getItem('access_token');
        // Adding AbortController for request cancellation
        const controller = new AbortController();
        config.signal = controller.signal;
        (config as any).abortController = controller;
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };

        if (error.response && error.response.status === 403 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise<string>((resolve: any, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then((token) => {
                    if (originalRequest.headers) {
                        originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    }
                    return apiClient(originalRequest);
                }).catch((err) => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise((resolve, reject) => {
                getStore().dispatch(refreshAccessToken())
                    .then(({ payload }: any) => {
                        const { access_token } = payload.data;
                        getStore().dispatch(setAccessToken(access_token))


                        apiClient.defaults.headers['Authorization'] = `Bearer ${access_token}`;
                        if (originalRequest.headers) {
                            originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
                        }
                        processQueue(null, access_token);
                        resolve(apiClient(originalRequest));
                    })
                    .catch((err: any) => {
                        processQueue(err, null);
                        console.log("error ", err);
                        localStorage.clear(); // Clear local storage to remove tokens
                        window.location.href = '/login'; // Redirect to login page
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }

        if (error.response && error.response.status === 401) {
            getStore().dispatch(clearToken());
        }

        return Promise.reject(error);
    }
);

export { apiClient };

