import React, { ReactNode } from 'react'
import './Card.scss'
interface CardPropsType {
    children: ReactNode;
    primaryBorderColor?: string;
    secondaryBorderColor?: string;
    rotation?: number;
    isGreen?: boolean
    customClass?: string;
}
const Card: React.FC<CardPropsType> = ({
    children,
    customClass,
    isGreen = true,
    rotation = 90
}) => {
    return (
        <div className={`card ${customClass}`} style={{
            background: isGreen ? `linear-gradient(${rotation}deg, #5F9937 3.01%, rgba(95, 153, 55, 0) 97.07%)` : `linear-gradient(${rotation}deg, #F7681C 0%, rgba(247, 104, 28, 0) 103.14%)`
        }} >
            {
                children
            }
        </div>
    )
}

export default Card