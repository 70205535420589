import { Card } from 'antd'
import React from 'react'
import { getImageURL } from '../../../app/utils/common/getImageUrl'
import Button from '../../atoms/Button/Button'
import { image } from '../../../app/utils/common/image'


const PostRequestDetailsCard = () => {
    return (
        <div className='post-card_PostRequestDetailsCard'>
            <div className='post_details_Data'>
                <div className='profile'>
                    <div className='profile_image_container'>
                        <img src={image.DEFAULT_PROFILE_IMAGE} className='profile_image' />
                    </div>
                    <div className='data_container'>
                        <div className='profile_data_container'>
                            <div className='profile_name'>Mark Lewis</div>
                            <div className='profile_contact'>+91 7675847384</div>
                            <div className=' profile_time'>2h ago</div>
                        </div>
                    </div>
                </div>
                <div className='post-details-card-description'>
                    Lorem Ipsum is simply dummy text of the printing
                    and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and
                    scrambled it to make a type specimen book. It has
                    survived not only five centuries, but also the leap into
                    electronic typesetting, remaining essentially
                    unchanged. It was popularised in the 1960s with the
                    release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing
                    software like Aldus PageMaker including versions
                    of Lorem Ipsum.
                    typesetting, remaining essentially
                    unchanged. It was popularised in the 1960s with the
                    release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing
                    software like
                </div>
                <div className='post-details-images-container'>
                    <img className='project-card-images' src={image.BUILDING_IMAGE} />
                    <img className='project-card-images' src={image.BUILDING_IMAGE} />
                    <img className='project-card-images' src={image.BUILDING_IMAGE} />
                </div>
            </div>

            <div className='reject-accept-btn_container'>
                <div className='reject-accept-btn_postDetails'>
                    <Button
                        buttonTitle="Reject"
                        onClick={() => { }}
                        className={'RejectButtonPostDetails'}
                    />
                    <Button
                        buttonTitle="Accept"
                        onClick={() => { }}
                        className={'RejectButton AcceptButton'}
                    />
                </div>
            </div>
        </div >

    )
}

export default PostRequestDetailsCard
