import React from 'react';
import './AddButton.scss';
import { image } from '../../../../app/utils/common/image';

interface AddButtonPropsType {
    padding?: string;
    plusIcon?: boolean;
    buttonClass?: string;
    iconClass?: string;
    onclick: () => void;
}

const AddButton: React.FC<AddButtonPropsType> = ({
    padding,
    plusIcon,
    buttonClass,
    iconClass,
    onclick
}) => {
    return (

        <button onClick={() => onclick()} className={`custom_add-btn ${buttonClass}`} style={{ padding: padding }}>
            {plusIcon && <img src={image.ADD_ICON} className={`plus_img ${iconClass}`} />
            }
            Add
        </button>

    )
}

export default AddButton