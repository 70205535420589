import React, { useState } from 'react'
import { Table } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { confirmModalProps } from '../../../../dto/confirm';
import ActionIcon from '../../../atoms/ActionIcon/ActionIcon';
import { image } from '../../../../app/utils/common/image';
import OptionMenu from '../../../atoms/OptionMenu/OptionMenu';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import TableComponent from '../../../atoms/TableComponent/Tablecomponent';


const ActionButtonContainer = () => {
    const [isMenuOpen, seIsMenuOpen] = useState(false);
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const options = [{
        name: "Edit",
        onClick: () => {
            navigate("manager/create")
        }
    },
    {
        name: "Mark as complete",
        onClick: () => {
            // setRemoveModal(true)
        }
    }
    ];

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this manager?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: () => { },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.USER_NAME_ICON
    };

    return (
        <div className='action_tab' style={{ position: "relative" }}>
            <div className='action-group' onClick={() => seIsMenuOpen(!isMenuOpen)}>
                <ActionIcon />
            </div>
            {isMenuOpen && <OptionMenu options={options} left={"-100px"} top={'32px'} onOutSideClick={() => seIsMenuOpen(false)} />}
            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}


const ScheduledVisitTable: React.FC = () => {
    const navigate = useNavigate()
    const columns: TableColumnsType = [
        {
            title: 'Profile',
            dataIndex: 'profile',
            render: (text, record) => (
                <img
                    src={record.profile}
                    alt="Profile"
                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => (
                <div onClick={() => navigate(`id`)} style={{ cursor: "pointer" }}>
                    {text}
                </div>
            )
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
        },
        {
            title: 'Email ID',
            dataIndex: 'emailId',
        },

        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Time Slot',
            dataIndex: 'timeSlot',
        },

        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record) => (
                <ActionButtonContainer />
            ),
        },
    ];


    const dataSource = Array.from({ length: 40 }).map((_, i) => ({
        key: i,
        profile: image.DEFAULT_PROFILE_IMAGE,
        name: `Edward King ${i}`,
        phoneNumber: 123456,
        emailId: 'abc@gmail.com',
        date: '10/28/2024',
        timeSlot: '09:00 AM - 12:00 PM',
        sites: 20,
    }));

    const [currentPage, setCurrentPage] = useState(1);
    const pageNumbers: any[] = [];
    const [pagination, setPagination] = useState({
        totalData: dataSource.length,
        currentPage: 1,
    });
    const pageSize = 7;
    const totalPages = Math.ceil(dataSource.length / pageSize);

    // Get data for the current page
    const currentData = dataSource.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    return (
        <div className='manager_dataTable'>

            <div>

                <TableComponent columns={columns}
                    dataSource={currentData}
                    pageNumbers={pageNumbers}
                    handlePageLink={handlePageChange}
                    totalProfile={pagination.totalData}
                    prevPage={() => handlePageChange(currentPage - 1)}
                    nextPage={() => handlePageChange(currentPage + 1)}
                    activePage={currentPage}
                    currentTotal={Math.min(currentPage * pageSize, dataSource.length)} />
            </div>
        </div>
    )


}

export default ScheduledVisitTable;
