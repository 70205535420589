import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
import { ProjectState } from "../../Slices/projectSlice";
import { getAllActiveProjects } from "../../API/ProjectAPI";
import { ProjectListType } from "../../../dto/Projects.dto";

interface ProjectResponse {
  success: boolean;
  status_code: number;
  message: string;
  data: {
    project_data: ProjectListType[];
    pagination: Pagination;
  };
}
interface Pagination {
  total_data: number;
  current_page: number;
  page_size: number;
  total_pages: number;
}

export const projectReducer = (
  builder: ActionReducerMapBuilder<ProjectState>
) => {
  builder

    //to get all projects
    .addCase(getAllActiveProjects.pending, (state) => {
      state.loading = true;
    })
    .addCase(
      getAllActiveProjects.fulfilled,
      (
        state,
        action: PayloadAction<{
          type: string;
          response: ProjectResponse;
        }>
      ) => {
        // state.status = "succeeded";
        state.loading = false;
        // state.Projects = action.payload;
        if (action.payload.type === "Active")
          state.ActiveProjects = action.payload.response.data.project_data;
        else
          state.CompletedProjects = action.payload.response.data.project_data;
      }
    )
    .addCase(
      getAllActiveProjects.rejected,
      (state, action: PayloadAction<any>) => {
        // Set status to failed
        state.loading = false;
        console.error("Fetch projects error:", action.payload);
      }
    );
};
