import React, { useEffect, useState } from 'react'
import { image } from '../../../../app/utils/common/image';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import { confirmModalProps } from '../../../../dto/confirm';
import Button from '../../../atoms/Button/Button';

import './ManagersDetails.scss'
import DetailsHeader from './DetailsHeader';
import ManagersSites from './ManagersSites';
import WorkedWithBrokers from './ManageManagerWorked';
import { useAppSelector } from '../../../../app/utils/hooks/reduxHook';


const ManagerDetails = () => {
    const navigate = useNavigate();
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const { id } = useParams();
    const { currentManager } = useAppSelector(state => state.manager)
    useEffect(() => {
        if (!currentManager || currentManager?.manager_id.toString() !== id) {
            navigate("/dashboard/manage-manager");
        }
    }, [])

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this manager?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: () => { },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.REMOVE_USER_ICON
    };

    return (
        <div className='create_project_container' >
            <div className='create_project_header_container'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    navigate(-1);
                }} />
                <div className='header_text'>Manager Details</div>
            </div>
            <div className='group-details-container'>
                <DetailsHeader />
                <div className='manage-broker-container'>
                    <ManagersSites />
                    <WorkedWithBrokers />
                </div>

            </div>

            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}

export default ManagerDetails