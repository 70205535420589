import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";

export const getAllBrokers = createAsyncThunk(
  "broker/getAllBrokers",
  async (
    { page, total_page = 10 }: { page: number; total_page?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_ALL_BROKERS}/?page=${page}&limit=${total_page}`
      );
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all brokers");
    }
  }
);

export const createBrokerAPI = createAsyncThunk(
  "broker/createBroker",
  async (
    data: {
      name: string;
      country_code: string;
      phone_number: string;
      email: string;
      profile_image?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `${ApiConstants.CREATE_BROKER}`,
        data
      );
      console.log("response.data CREATE_BROKER:>> ", response.data);
      return response.data.data;
    } catch (error: any) {
      console.log("error print", error);
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const updateBrokerAPI = createAsyncThunk(
  "broker/updateBrokerAPI",
  async (
    data: {
      cpId: number;
      name?: string;
      country_code?: string;
      phone_number?: string;
      email?: string;
      profile_image?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      let { cpId, ...reqObj } = data;
      const response = await apiClient.put(
        `${ApiConstants.UPDATE_BROKER}/${cpId}/update`,
        reqObj
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to update broker");
    }
  }
);

export const deleteBrokerAPI = createAsyncThunk(
  "broker/deleteBrokerAPI",
  async (data: { cpId: number }, { rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        `${ApiConstants.DELETE_BROKER}/${data.cpId}/delete`
      );
      return response.data.data;
    } catch (error: any) {
      console.log("error print", error);
      return rejectWithValue(error.response?.data || "Failed to delete broker");
    }
  }
);

export const getCPsManagerAPI = createAsyncThunk(
  "broker/getCpsManager",
  async (
    {
      cpId,
      page,
      total_page = 10,
    }: { cpId: number | any; page: number; total_page?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${ApiConstants.GET_CPs_MANAGER}/${cpId}/managers/?page=${page}&limit=${total_page}`
      );
      console.log("getCPsManagerAPI data :>> ", response.data);
      return response.data.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || "Failed get all brokers");
    }
  }
);
