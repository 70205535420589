import React, { ReactNode } from 'react'
import "./Modal.scss"
import { image } from '../../../app/utils/common/image';
interface ModalProps {
    children: ReactNode;
    isClose?: boolean;
    onClose?: () => void;

}
const Modal: React.FC<ModalProps> = ({ children, isClose, onClose }) => {
    return (
        <div className='modal_container'>

            <div className='modal_content'>
                {
                    isClose &&
                    <div className='modal_close_icon'>
                        <img src={image.CLOSE_ICON_GRAY} className='close_icon' onClick={() => onClose && onClose()} />
                    </div>
                }
                {
                    children
                }
            </div>
        </div>
    )
}

export default Modal