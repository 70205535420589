import React, { FC, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import LoginPage from '../../components/pages/LoginPage/LoginPage'
import Homepage from '../../components/organisms/Dashboard/HomePage/Homepage'
import { useAppDispatch, useAppSelector } from '../utils/hooks/reduxHook'
import { getUserData } from '../../redux/API/UserAPI'
import Dashboard from '../../components/pages/Dashboard/Dashboard'
import ProtectedRoute from './ProtectedRoute'
import AllProjects from '../../components/organisms/Dashboard/Projects/AllProjects'
import CreateProjectPage from '../../components/organisms/Dashboard/Projects/CreateProjectPage'
import AllRoles from '../../components/organisms/Dashboard/RoleManagement/AllRoles'
import LoginFormV1 from '../../components/organisms/AuthScreens/LoginFormV1'
// import LoginForm from '../../components/organisms/LoginForm/LoginForm'
import SignUpScreen from '../../components/organisms/AuthScreens/SignUpScreen'
import NotFound from '../../components/organisms/Dashboard/NotFound'
import PrivacyPolicy from '../../components/pages/Extra/privacyPolicy'
import Profile from '../../components/organisms/Dashboard/Profile/Profile'
import ProjectsTabs from '../../components/molecules/SiteManagement/ProjectsTab'
import SiteManagement from '../../components/organisms/Dashboard/SiteManagement/SiteManagement'
import Managers from '../../components/organisms/Managers/Managers'
import RegistrationRequest from '../../components/organisms/RegistrationRequest/RegistrationRequest'
import SingleSite from '../../components/organisms/Dashboard/SiteManagement/SingleSite/SingleSite'
import ContactUs from '../../components/organisms/ContactUs/ContactUs'
import PostRequests from '../../components/organisms/PostRequests/PostRequests'
import ManageGroups from '../../components/organisms/ManageGroups/ManageGroups'
import ManageGroupDetails from '../../components/molecules/ManageGroupsComponent/ManageGroupDetails/ManageGroupDetails'
import ManageBrokers from '../../components/organisms/ManageBrokers/ManageBrokers'
import ManageBrokerDetails from '../../components/molecules/ManageBrokersComponent/ManageBrokerDetails/ManageBrokerDetails'
import AddManageBroker from '../../components/molecules/ManageBrokersComponent/AddManageBroker/AddManageBroker'
import AddManager from '../../components/molecules/ManageManagerComponent/AddManager/AddManager'
import BrokerUnderManagerTable from '../../components/molecules/ManageManagerComponent/AddBrokerUnderManager/AddBrokerUnderManager'
import ManagerDetails from '../../components/molecules/ManageManagerComponent/ManagerDetail/ManagerDetails'
import PageNotFound from '../../components/pages/Extra/404'
import VisitManagement from '../../components/organisms/Dashboard/VisitManagement/VisitManagement'
import SingleVisitDetails from '../../components/molecules/VisitManagement/SingleVisitDetails/SingleVisitDetails'
import PostRequestDetailScreen from '../../components/organisms/PostRequests/PostRequestDetailScreen/PostRequestDetailScreen'

const AppRoutes: FC = () => {
    const { access_token } = useAppSelector(state => state.auth)
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith("/extra")) {
            return;
        }
        if (!localStorage.getItem('access_token') && !access_token) {
            navigate("/login");
        }

    }, [])

    return (
        <>
            <Routes>
                <Route path='/login' element={<LoginFormV1 />} />
                {/* <Route path='/old-login' element={<LoginForm />} /> */}
                <Route path='/sign-up' element={<SignUpScreen />} />

                {/* <Route path='/home' element={<Homepage />} /> */}
                <Route path="/dashboard" element={<Dashboard />} >
                    <Route path='' element={<ProtectedRoute component={Homepage} />} />
                    <Route path='site-management' element={<ProtectedRoute component={SiteManagement} />} />
                    <Route path='site-management/create' element={<ProtectedRoute component={CreateProjectPage} />} />
                    <Route path='site-management/site/:id' element={<ProtectedRoute component={SingleSite} />} />

                    <Route path='visit-management' element={<ProtectedRoute component={VisitManagement} />} />
                    <Route path='visit-management/visit/:id' element={<ProtectedRoute component={SingleVisitDetails} />} />

                    <Route path='manage-group' element={<ProtectedRoute component={ManageGroups} />} />
                    <Route path='manage-group/:type' element={<ProtectedRoute component={ManageGroupDetails} />} />
                    <Route path='manage-broker' element={<ProtectedRoute component={ManageBrokers} />} />
                    <Route path='manage-broker/:id' element={<ProtectedRoute component={ManageBrokerDetails} />} />
                    <Route path='manage-manager' element={<ProtectedRoute component={Managers} />} />
                    <Route path='manage-manager/manager/:type' element={<ProtectedRoute component={AddManager} />} />
                    <Route path='manage-manager/manager/broker/BrokerUnderManagerTable' element={<ProtectedRoute component={BrokerUnderManagerTable} />} />
                    <Route path='manage-manager/:id' element={<ProtectedRoute component={ManagerDetails} />} />
                    <Route path='manage-broker/broker/:type' element={<ProtectedRoute component={AddManageBroker} />} />
                    <Route path='manage-manager/:id' element={<ProtectedRoute component={ManagerDetails} />} />

                    <Route path='role/all' element={<ProtectedRoute component={AllRoles} />} />
                    <Route path='me' element={<ProtectedRoute component={Profile} />} />

                    <Route path='*' element={<ProtectedRoute component={NotFound} />} />

                    <Route path='registration-request' element={<ProtectedRoute component={RegistrationRequest} />} />
                    <Route path='contact-us' element={<ProtectedRoute component={ContactUs} />} />
                    <Route path='post-requests' element={<ProtectedRoute component={PostRequests} />} />
                    <Route path='post-requests/:id' element={<ProtectedRoute component={PostRequestDetailScreen} />} />


                    {/* <Route path='project/all' element={<ProtectedRoute component={AllProjects} />} />
                    <Route path='project/create' element={<ProtectedRoute component={CreateProjectPage} />} />
                    <Route path='project/projectDetails' element={<ProtectedRoute component={ViewProjectDetails} />} /> */}
                </Route>
                <Route path="*" element={<PageNotFound />}>
                </Route>
                <Route path='/extra/privacy-policy' element={<PrivacyPolicy />} />
                {/* <Route path='/' element={<Navigate to="/login" />} /> */}
            </Routes>
        </>
    )
}

export default AppRoutes