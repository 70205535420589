import React, { FC, useRef } from 'react'
import Input from '../../../atoms/Input/Input'
import { image } from '../../../../app/utils/common/image'
import { getImageURL } from '../../../../app/utils/common/getImageUrl'
import ErrorText from '../../../atoms/Error/error'
interface ManagerGroupHeaderProps {
    name: string,
    setName: React.Dispatch<React.SetStateAction<string>>,
    profilePhoto: string | null | undefined,
    newProfilePhoto: File | undefined,
    inputRef: any,
    isEditMode: any,
    showError: boolean,
}

const ManageGroupHeader: FC<ManagerGroupHeaderProps> = (props) => {
    const { name, setName, profilePhoto, newProfilePhoto, inputRef, isEditMode, showError } = props;
    return (
        <div className='group-container'>
            <div>
                <div className='group-photo'>Group Photo</div>
                <div onClick={() => isEditMode && inputRef.current?.click()}>
                    <img src={newProfilePhoto ? URL.createObjectURL(newProfilePhoto) : profilePhoto ? getImageURL(profilePhoto) : image.DEFAULT_PROFILE_IMAGE} className='group-profile' />
                    <img src={image.CAMERA_PHOTO} alt="camera icon" className="group-camera_image" />
                </div>
            </div>
            <div className='group-name-container'>
                <div className='group-photo'>Group Name</div>
                <Input
                    placeholder="Enter Group Name"
                    type="text"
                    name="group Name"
                    id="roleName"
                    value={name}
                    onChange={(e) => isEditMode && setName(e.target.value)}
                    disabled={!isEditMode}
                />
                {showError && name.length == 0 && <ErrorText message="Please enter group name" />}
            </div>
        </div>
    )
}

export default ManageGroupHeader