import React from 'react'
import RegistrationReqTable from '../../molecules/RegistrationReqTable/RegistrationReqTable';
import './RegistrationRequest.scss'

const RegistrationRequest = () => {
    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='allManager'>
                    Registration Request
                </div>
            </div>

            <div className='manager_data_table'>
                <RegistrationReqTable />
            </div>
        </div>
    )
}

export default RegistrationRequest