export const ApiConstants = {
    SENDOTP: '/api/auth/send-otp',
    LOGIN: '/api/auth/login',
    RESENDOTP: '/api/auth/resend-otp',
    REFRESHTOKEN: '/api/auth/refresh-access-token',
    REGISTER_OTP: '/api/auth/register/sendotp',
    LOGOUT: '/api/auth/logout',
    USER_PROFILE: '/api/profile',
    UPLOAD_FILE: '/api/file/upload',
    GETALL_PROJECT: '/api/admin/project/all',
    UPDATE_USER_DATA: '/api/profile/info',
    GET_ALL_MANAGERS: '/api/admin/manager/all',
    CREATE_MANAGER: '/api/admin/manager/create',
    UPDATE_MANAGER: '/api/admin/manager',
    GET_ALL_BROKERS: '/api/admin/channel-partner/all',
    GET_ALL_GROUPS: '/api/admin/group/all',
    CREATE_GROUP: '/api/admin/group/create',
    UPDATE_GROUP: '/api/admin/group',
    CREATE_BROKER: '/api/admin/channel-partner/create',
    UPDATE_BROKER: '/api/admin/channel-partner',
    DELETE_BROKER: '/api/admin/channel-partner',
    GET_CPs_MANAGER: '/api/admin/channel-partner'
    // GET_CPs_MANAGER:'/api/admin/channel-partner/:cpId/managers'
}