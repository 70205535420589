import React, { useEffect, useState } from 'react'
import { image } from '../../../../app/utils/common/image';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from '../../../atoms/confirmation-modal/confirmation-modal';
import { confirmModalProps } from '../../../../dto/confirm';
import Button from '../../../atoms/Button/Button';
import ManageBrokerHeader from './ManageBrokerHeader';
import './ManageBrokerDetails.scss'
import ManageBrokerSitesVisited from './ManageBrokerSitesVisited';
import ManageBrokerWorked from './ManageBrokerWorked';
import { useAppDispatch, useAppSelector } from '../../../../app/utils/hooks/reduxHook';
import { BrokerType } from '../../../../dto/Broker.dto';
import { getCPsManagerAPI } from '../../../../redux/API/BrokerAPI';

const ManageBrokerDetails = () => {
    const navigate = useNavigate();
    const [removeModal, setRemoveModal] = useState<boolean>(false);
    const [currentBroker, setCurrentBroker] = useState<BrokerType | null>(null);
    const dispatch = useAppDispatch();
    const { brokers, loading, pagination, managers } = useAppSelector(state => state.broker)
    const { id } = useParams<{ id: string }>();


    console.log("Current BrokerID: ", id)
    console.log('brokers ManageBrokerDetails:>> ', brokers);

    useEffect(() => {
        if (id && brokers.length > 0) {
            const broker = brokers.find((broker) => broker.cp_id.toString() === id);
            setCurrentBroker(broker || null);
        }
    }, [id, brokers]);

    useEffect(() => {
        if (id) {
            dispatch(getCPsManagerAPI({ cpId: Number(id), page: pagination.current_page }));
        }
    }, [id, pagination.current_page, dispatch]);


    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to remove this broker?",
        confirmModal: removeModal,
        setConfirmModal: setRemoveModal,
        buttons: ['Yes, Remove', 'Cancel'],
        onPressPositive: () => {
        },
        onPressNegative: () => { setRemoveModal(false) },
        image: image.REMOVE_USER_ICON
    };

    return (
        <div className='create_project_container' >
            <div className='create_project_header_container'>
                <img className='back_icon' src={image.BACK_ICON} onClick={() => {
                    navigate(-1);
                }} />
                <div className='header_text'>Broker Details</div>
            </div>
            <div className='group-details-container'>
                <ManageBrokerHeader currentBrokerDetails={currentBroker} />
                <div className='manage-broker-container'>
                    <ManageBrokerSitesVisited />
                    <ManageBrokerWorked managers={managers || []} />
                </div>

            </div>

            {removeModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>
    )
}

export default ManageBrokerDetails