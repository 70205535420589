
import { Checkbox, Modal } from 'antd'
import React, { useState } from 'react'
import Input from '../../atoms/Input/Input';

interface createRoleModalProps {
    AddRoleModalOpen: boolean;
    setAddRoleModalOpen: (isOpen: boolean) => void;
}
const CreateRoleModal: React.FC<createRoleModalProps> = ({ AddRoleModalOpen, setAddRoleModalOpen }) => {
    const [roleName, setRoleName] = useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRoleName(event.target.value)
    }

    const modules = [
        { name: 'Project', permissions: ['Create', 'Read', 'Edit', 'Delete'] },
        { name: 'Create Post', permissions: ['Create', 'Read', 'Edit', 'Delete'] },
    ];

    return (
        <div>
            <Modal
                open={AddRoleModalOpen}
                onCancel={() => setAddRoleModalOpen(false)}
                footer={null}>
                <h1 className='modalHeader'>Create Role</h1>
                <div className='modalContainer'>
                    <Input
                        label="Role Name"
                        placeholder="Enter Role Name"
                        type="text"
                        name="role Name"
                        id="roleName"
                        value={roleName}
                        onChange={handleInputChange}
                        className="input-class"
                    />


                    {/* <h2>Modules:</h2> */}
                    <div className="modules-container">
                        {modules.map((module) => (
                            <div key={module.name} className="module">
                                <h3>{module.name}</h3>
                                <Checkbox.Group>
                                    {module.permissions.map((permission) => (
                                        <Checkbox key={permission} value={permission}>
                                            {permission}
                                        </Checkbox>
                                    ))}
                                </Checkbox.Group>
                            </div>
                        ))}
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default CreateRoleModal
