import React, { useEffect, useState } from 'react'
import "./Managers.scss"
import { Button } from 'antd'
import { image } from '../../../app/utils/common/image'
import { useNavigate } from 'react-router-dom'
import ManagerDataTable from '../../molecules/ManageManagerComponent/ManagerDataTable'
import { PaginationType } from '../../../dto/Pagination.dto'
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook'


const Managers = () => {
    const navigate = useNavigate()


    return (
        <div className='manager_container'>
            <div className='manager_header'>
                <div className='allManager'>
                    All Managers
                </div>

                <div className='button_container'>
                    <div className='btn'>
                        <img className='btn_icon' src={image.FILTER_ICON} />
                        <div>
                            Filter
                        </div>
                    </div>
                    <div className='btn' onClick={() => navigate("manager/create")}>
                        <img className='btn_icon' src={image.ADD_ICON} />
                        <div>
                            Add
                        </div>
                    </div>
                </div>
            </div>

            <div className='manager_data_table'>
                <ManagerDataTable />
            </div>
        </div>
    )
}

export default Managers