import React, { FC } from 'react';
import './Pagination.scss';
import { image } from '../../../app/utils/common/image';

interface PaginationProps {
    pageNumbers: number[];
    handlePageLink: (page: number) => void;
    prevPage: () => void;
    nextPage: () => void;
    totalProfile: number;
    activePage: number;
    currentTotal: number
}

const Pagination: FC<PaginationProps> = (props) => {
    const { pageNumbers, handlePageLink, prevPage, nextPage, totalProfile, activePage, currentTotal } = props;
    const isPrevDisabled = activePage <= 1;
    const isNextDisabled = activePage >= pageNumbers.length;

    return (
        <div className='pagination-container'>
            <div className='total-pagination-no'> Showing {currentTotal} from {totalProfile} data</div>
            <nav className='list-disabled'>
                <ul className='pagination-list'>

                    {/* Previous Button */}
                    <li
                        className={`prev-page-button ${isPrevDisabled ? 'disabled' : ''}`}
                        onClick={!isPrevDisabled ? prevPage : undefined}
                    >
                        <div>
                            <img src={image.PREV_ICON} alt="Previous Icon" />
                            <img src={image.PREV_LIGHT_ICON} alt="Previous Light Icon" />
                        </div>
                        <div>Previous</div>
                    </li>

                    {/* Page Number Buttons */}
                    {pageNumbers.map((number: any) => {
                        return (
                            <button
                                key={number}
                                onClick={() => handlePageLink(number)}
                                className={`pagination-paper  ${activePage === number ? 'pagination-paper-inactive' : ''}`}
                            >
                                {number}
                            </button>
                        )
                    })}

                    {/* Next Button */}
                    <li
                        className={`prev-page-button ${isNextDisabled ? 'disabled' : ''}`}
                        onClick={!isNextDisabled ? nextPage : undefined}
                    >
                        <div>Next</div>
                        <div>
                            <img src={image.PREV_ICON} style={{ transform: 'scaleX(-1)' }} alt="Next Icon" />
                            <img src={image.PREV_LIGHT_ICON} style={{ transform: 'scaleX(-1)' }} alt="Next Light Icon" />
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Pagination