import React, { useEffect } from 'react'
import { Tabs } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/utils/hooks/reduxHook';
import ManagerList from './ManagerList';
import BrokerList from './BrokerList';
const TopPerformerList = () => {
  const { ActiveProjects, CompletedProjects } = useAppSelector(state => state.project)
  const dispatch = useAppDispatch();
  useEffect(() => {

  }, [dispatch])
  const ManagerTabHeader = (data: { number: any }) => (
    <div className='tab_selected'>
      Managers
      <div className='active_project_number'>{data.number}</div>
    </div>
  )
  const BrokerTabHeader = (data: { number: any }) => (
    <div className='tab_selected'>
      Brokers
      <div className='completed_projects_number'>{data.number}</div>
    </div>
  )
  return (

    <div>
      <Tabs
        defaultActiveKey="1"
        tabBarStyle={{
          borderBottom: '1px solid #C4C4C4', // Underline color for tabs
        }}
        items={[
          {
            label: <ManagerTabHeader number={0} />,
            key: '1',
            children: <ManagerList />,
          },
          {
            label: <BrokerTabHeader number={200} />,
            key: '2',
            children: <BrokerList />,
          },

        ]}
      />
    </div>

  )
}

export default TopPerformerList;